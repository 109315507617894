<div class="footerBg">
    <div class="FooterContent">
        <div class="container">
            <h6>Newsletter</h6>
            <h2>Subscribe to Our <br> Newsletter. <strong>Get Onboard!</strong>
            </h2>
            <form [formGroup]="formGroup" class="needs-validation" novalidate (ngSubmit)="submitForm()">
                <div class="footForm">
                    <div class="form-group">
                        <input type="email" placeholder="Enter Your Email ID" #email class="form-control formText"
                            formControlName="email" required>
                    </div>
                    <button type="submit" [disabled]="formGroup.invalid" class="btn btn-custom-green btn-footer">Subscribe Now</button>
                </div>
            </form>
            <p class="subscribedUser" *ngIf="show">
                <i class="fa fa-check"></i> You have successfully subscribed to our newsletter
            </p>
            <div class="footBottom">
                <p>Copyright &copy; {{ currentYear }} Devirsa. All Rights Reserved.</p>
                <div class="rightContent">
                    <ul class="quickLinks">
                        <li><a href="#" routerLinkActive="active" routerLink="/home">Home</a></li>
                        <li><a href="#" routerLinkActive="active" routerLink="/services">Services</a></li>
                        <!-- <li><a href="#" routerLinkActive="active" routerLink="/blog">Insights</a></li> -->
                        <li><a href="#" routerLinkActive="active" routerLink="/about">About</a></li>
                        <!-- <li><a href="#" routerLinkActive="active" routerLink="/work">Work</a></li> -->
                        <li><a href="#" routerLinkActive="active" routerLink="/contact">Contact</a></li>
                        <li><a href="#" routerLinkActive="active" routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="#" routerLinkActive="active" routerLink="/terms-condition">Terms & Conditions</a>
                        </li>
                    </ul>
                    <!-- <div class="sIcons">
                        <ul>
                            <li><a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>