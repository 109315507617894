<div class="services-provided">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/ui-ux/product-prototyping.png" alt="" class=""></div>
            </div>
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>Product Prototyping
                    </h3>
                    <p>Leverage proper user feedback to achieve the desired success for your product. At Devirsa, we have a highly competent team of product designers and engineers, developing and innovating revolutionary ideas. Our comprehensive prototyping services focus severely on creating a meaningful user experience. Right from concept, development to designing, we integrate aesthetics and functionality to turn ideas into award-winning products.
                    </p>
                    <ul>
                        <li> <strong>Test Your Concept</strong> 
                            <p> Validate your product idea with the help of a real, working model & benefit from helpful feedback from the test users.</p>
                        </li>
                        <li> <strong>Pitch Investors & Customers</strong> 
                            <p> Impressing your investors and customers turn easier by presenting your vision, which is real and tangible.</p>
                        </li>
                        
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>

                </div>
            </div>
        </div>
        <div class="row flex-column-reverse flex-md-row service-row-Mt">
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>Usability Testing & Validation
                    </h3>
                    <p>To design excellence and improve UX, we test functional prototypes constantly. With user testing & validation, we tend to understand how users experience your product. Also, our intensive testing process provides data-driven insights to improve your product. While we work to find disjointed user flows, we are always up with exclusive ideas to craft intuitive user experience.
                    </p>
                    <ul>
                        <li> <strong>Unified Design
                        </strong> 
                            <p> Deliver a unified, holistic, and efficient user experience rather than component-by-component experience, which often leads to poor overall service performance.
                            </p>
                        </li>
                        <li> <strong>Minimum Viable Service (MVS)
                        </strong> 
                            <p> We design services as a minimum viable service (MVS) and then deploy. Based on the user feedback, we keep iterating the design.
                            </p>
                        </li>
                        
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>

                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/ui-ux/usability-testing.png" alt="" class="">
                </div>
            </div>
        </div>
        <div class="row service-row-Mt">
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/ui-ux/service-design.png" alt="" class=""></div>
            </div>
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>Service Design
                    </h3>
                    <p>We develop a holistic view of service that enables design across all of your customer digital touchpoints, incorporating technology and 0perations around user needs at any point in the journey. We have a team of experienced strategists and designers, which is best-known for creating customized service plans for your business needs.   
                    </p>
                    <ul>
                        <li> <strong>Boost Your Brand Presence
                        </strong> 
                            <p>Utilizing digital strategies like social media & email marketing increase awareness of your brand and ultimately earn more revenue.</p>
                        </li>
                        <li> <strong>Drive More Qualified Leads</strong> 
                            <p> Stop wasting money to market to people who don’t need your product. Leverage advanced targeting to attain the maximum amount of qualified traffic possible. </p>
                        </li>
                        
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>

                </div>
            </div>
        </div>
        <div class="row flex-column-reverse flex-md-row">
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>UX Consulting
                    </h3>
                    <p>Building a next-gen user experience is possible with expert consulting. At Devirsa, we analyze your customer behavior, requirements, and pain points to help you visualize your product's interface. Our UX consulting goes beyond simple interface tweaks. We spot potential improvements related to the overall design, functionality, and usability. Now take your brand experience to the next level, increase conversion, drive customer loyalty, and do much more.   
                    </p>
                    <ul>
                        <li> <strong>End-to-End UI/UX Assessment</strong> 
                            <p>We kick-off the process with an in-depth understanding of your business objectives, followed by an analysis of what is being built, why, and for whom.</p>
                        </li>
                        <li> <strong>Measure, Analyze & Improve</strong> 
                            <p> We perform a hands-on evaluation, usability testing, and heat map analysis to assess & improve your final product as per user expectations.</p>
                        </li>
                        
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>

                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/ui-ux/ux-consulting.png" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</div>