<div class="aboutSlider">
    <div class="container">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" role="listbox">
                <!-- Slide One - Set the background image for this slide in the line below -->
                <div class="carousel-item active">
                    <div class="carousel-caption d-md-block">
                        <h2>Our journey starts with <strong> YOU</strong> and ends with <strong> YOU</strong>, with your <strong> CUSTOMERS</strong> as co-passengers.
                        </h2>
                        <p class="lead">Our foundation lies on a simple mantra - if our customers thrive, we thrive, and customers will thrive only if their customers thrive. 
                        </p>
                        <p class="lead">Thus, we design and build every digital product keeping our customer’s customers in mind. We call this customer-centric, custom software development approach as <strong> EMPATHY. ENGINEER. EXPERIENCE.</strong>
                        </p>
                        
                    </div>
                </div>
                <!-- Slide Two - Set the background image for this slide in the line below -->
                <!-- <div class="carousel-item">
                    <div class="carousel-caption d-md-block">
                        <h2>Everything we do<br>starts with <strong>People</strong></h2>
                        <p class="lead">Ours is a co-creative process that starts with understanding what your people need — your team and your customers alike. We bring into the mix our people, which is a carefully selected cross-section of talent we choose to work on your project.</p>
                        
                    </div>
                </div> -->
                <!-- Slide Three - Set the background image for this slide in the line below -->
                <!-- <div class="carousel-item">
                    <div class="carousel-caption d-md-block">
                        <h2>Everything we do<br>starts with <strong>People</strong></h2>
                        <p class="lead">Ours is a co-creative process that starts with understanding what your people need — your team and your customers alike. We bring into the mix our people, which is a carefully selected cross-section of talent we choose to work on your project.</p>
                        
                    </div>
                </div> -->
            </div>
            <!-- <div class="slide-arrow">
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <img src="../../../../assets/aboutArrowLeft.svg" alt="">
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <img src="../../../../assets/aboutArrowRight.svg" alt="">
                </a>
            </div> -->
        </div>
    </div>
</div>