<!-- <div class="MainContent">
  <header>
    <app-header></app-header>
  </header>
  <section class="content">

    <div class="slider">
      <app-banner></app-banner>
      <app-about></app-about>
      <app-services></app-services>
      <app-case-study></app-case-study>
      <app-slider></app-slider>
      <app-blogs></app-blogs>
    </div>
  </section>
  <footer>
    <app-footer></app-footer>
  </footer>
</div> -->
<router-outlet></router-outlet>