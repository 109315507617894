import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-s-detail-content',
  templateUrl: './s-detail-content.component.html',
  styleUrls: ['./s-detail-content.component.scss']
})
export class SDetailContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
