<div class="MainContent">
    <header>
      <app-header></app-header>
    </header>
    <section class="content">
      <app-case-banner></app-case-banner>
      <app-s-detail-content></app-s-detail-content>
      <app-case-study></app-case-study>
      <app-get-started></app-get-started>
    </section>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>