<div class="MainContent">
    <header>
        <app-header></app-header>
    </header>
    <section class="content">
        <app-blog-banner></app-blog-banner>
        <div class="container">
            <div class="page-header">
                <h3>From the Blogs  </h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="blog-content">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <article id="">
                                    <div class="top-img">
                                        <a href=""  routerLinkActive="active" routerLink="/blog-detail">
                                            <img src="../../../assets/blog/blog-1.png" alt="" class="img-fluid" />
                                        </a>
                                        <div class="meta">
                                            <p>Blog</p>
                                            <span class="date">4 Aug 2020</span>
                                        </div>
                                    </div>
                                    <a href="" routerLinkActive="active" routerLink="/blog-detail" class="blog-title">
                                        Donec dapibus mauris id odio ornare tempus.
                                    </a>
                                    <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy
                                        emo.
                                    </p>
                                    <div class="author">
                                        <h4>Amanda Patel</h4>
                                        <p>Frontend Developer, Riverdale</p>
                                    </div>
                                </article>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <article id="">
                                    <div class="top-img">
                                        <a href="">
                                            <img src="../../../assets/blog/blog-2.png" alt="" class="img-fluid" />
                                        </a>
                                        <div class="meta">
                                            <p>Blog</p>
                                            <span class="date">4 Aug 2020</span>
                                        </div>
                                    </div>
                                    <a href="" routerLinkActive="active" routerLink="/blog-detail" class="blog-title">
                                        Donec dapibus mauris id odio ornare tempus.
                                    </a>
                                    <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy
                                        emo.
                                    </p>
                                    <div class="author">
                                        <h4>Adam Reynolds</h4>
                                        <p>Frontend Developer, Riverdale</p>
                                    </div>
                                </article>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <article id="">
                                    <div class="top-img">
                                        <a href="">
                                            <img src="../../../assets/blog/blog-3.png" alt="" class="img-fluid" />
                                        </a>
                                        <div class="meta">
                                            <p>Blog</p>
                                            <span class="date">4 Aug 2020</span>
                                        </div>
                                    </div>
                                    <a href="" routerLinkActive="active" routerLink="/blog-detail" class="blog-title">
                                        Donec dapibus mauris id odio ornare tempus.
                                    </a>
                                    <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy
                                        emo.
                                    </p>
                                    <div class="author">
                                        <h4>Tyler Pierce</h4>
                                        <p>Frontend Developer, Riverdale</p>
                                    </div>
                                </article>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <article id="">
                                    <div class="top-img">
                                        <a href="">
                                            <img src="../../../assets/blog/blog-4.png" alt="" class="img-fluid" />
                                        </a>
                                        <div class="meta">
                                            <p>Blog</p>
                                            <span class="date">4 Aug 2020</span>
                                        </div>
                                    </div>
                                    <a href="" routerLinkActive="active" routerLink="/blog-detail" class="blog-title">
                                        Donec dapibus mauris id odio ornare tempus.
                                    </a>
                                    <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy
                                        emo.
                                    </p>
                                    <div class="author">
                                        <h4>Johnny Hayes</h4>
                                        <p>Frontend Developer, Riverdale</p>
                                    </div>
                                </article>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <article id="">
                                    <div class="top-img">
                                        <a href="">
                                            <img src="../../../assets/blog/blog-5.png" alt="" class="img-fluid" />
                                        </a>
                                        <div class="meta">
                                            <p>Blog</p>
                                            <span class="date">4 Aug 2020</span>
                                        </div>
                                    </div>
                                    <a href="" routerLinkActive="active" routerLink="/blog-detail" class="blog-title">
                                        Donec dapibus mauris id odio ornare tempus.
                                    </a>
                                    <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy
                                        emo.
                                    </p>
                                    <div class="author">
                                        <h4>Jessica Fox</h4>
                                        <p>Frontend Developer, Riverdale</p>
                                    </div>
                                </article>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <article id="">
                                    <div class="top-img">
                                        <a href="">
                                            <img src="../../../assets/blog/blog-6.png" alt="" class="img-fluid" />
                                        </a>
                                        <div class="meta">
                                            <p>Blog</p>
                                            <span class="date">4 Aug 2020</span>
                                        </div>
                                    </div>
                                    <a href="" routerLinkActive="active" routerLink="/blog-detail" class="blog-title">
                                        Donec dapibus mauris id odio ornare tempus.
                                    </a>
                                    <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy
                                        emo.
                                    </p>
                                    <div class="author">
                                        <h4>Jasmine Hughes</h4>
                                        <p>Frontend Developer, Riverdale</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <nav class="custom-pagination">
                            <ul class="pagination justify-content-center">
                                <li class="page-item previous disabled"><a class="page-link" href="#" aria-label="Previous"><i class="fa fa-angle-left"></i></a></li>
                                <li class="page-item active"><a class="page-link" href="#">1 <span class="sr-only">(current)</span></a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item next"><a class="page-link" href="#" aria-label="Next"><i class="fa fa-angle-right"></i></a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
                <aside class="col-md-4">
                    <div class="page-header">
                        <h3>Most popular</h3>
                    </div>
                    <div class="side-content">
                        <ul>
                            <li>
                                <div class="leftContent">
                                    <h4>
                                        <a href="" routerLinkActive="active" routerLink="/blog-detail">Ligula urna, fermentum ut semper in, tincidunt</a>
                                    </h4>
                                    <p class="auth-name">
                                        Chad Evans
                                    </p>
                                </div>
                                <div class="rightImage">
                                    <img src="../../../assets/blog/blog-right-1.png" alt="">
                                </div>
                            </li>
                            <li>
                                <div class="leftContent">
                                    <h4>
                                        <a href="" routerLinkActive="active" routerLink="/blog-detail">Ligula urna, fermentum ut semper in, tincidunt</a>
                                    </h4>
                                    <p class="auth-name">
                                        Chad Evans
                                    </p>
                                </div>
                                <div class="rightImage">
                                    <img src="../../../assets/blog/blog-right-1.png" alt="">
                                </div>
                            </li>
                            <li>
                                <div class="leftContent">
                                    <h4>
                                        <a href="" routerLinkActive="active" routerLink="/blog-detail">Ligula urna, fermentum ut semper in, tincidunt</a>
                                    </h4>
                                    <p class="auth-name">
                                        Chad Evans
                                    </p>
                                </div>
                                <div class="rightImage">
                                    <img src="../../../assets/blog/blog-right-1.png" alt="">
                                </div>
                            </li>
                            <li>
                                <div class="leftContent">
                                    <h4>
                                        <a href="" routerLinkActive="active" routerLink="/blog-detail">Ligula urna, fermentum ut semper in, tincidunt</a>
                                    </h4>
                                    <p class="auth-name">
                                        Chad Evans
                                    </p>
                                </div>
                                <div class="rightImage">
                                    <img src="../../../assets/blog/blog-right-1.png" alt="">
                                </div>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
    </section>
    <footer>
        <app-footer></app-footer>
    </footer>
</div>