import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './Pages/about-us/about-us.component';
import { HomeComponent } from './Pages/Home/home.component';
import { OurServicesComponent } from './Pages/our-services/our-services.component';
import { ContactUsComponent } from './Pages/contact-us/contact-us.component';
import {ServiceOneComponent } from './Pages/our-services/all-services/service-one/service-one.component';
import {ServiceTwoComponent } from './Pages/our-services/all-services/service-two/service-two.component';
import {ServiceThreeComponent } from './Pages/our-services/all-services/service-three/service-three.component';
import { ServiceFourComponent} from './Pages/our-services/all-services/service-four/service-four.component';
import { BlogComponent } from './Pages/blog/blog.component';
import { BlogDetailComponent } from './Pages/blog/blog-detail/blog-detail.component';
import { WorkComponent } from './Pages/work/work.component';
import {  PrivacyPolicyComponent } from './Pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './Pages/terms-conditions/terms-conditions.component';
import { CaseStudyPageComponent } from './Pages/case-study-page/case-study-page.component';
import { ErrorPageComponent } from './Pages/error-page/error-page.component';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, pathMatch: 'prefix'},
  {path: 'about', component: AboutUsComponent, pathMatch: 'prefix' },
  {path: 'services', component: OurServicesComponent, pathMatch: 'prefix' },
  {path: 'contact', component: ContactUsComponent, pathMatch: 'prefix'},
  {path: 'services/cloud-service', component: ServiceOneComponent, pathMatch: 'prefix'},
  {path: 'services/custom-app-development', component: ServiceTwoComponent, pathMatch: 'prefix'},
  {path: 'services/ui-ux', component: ServiceThreeComponent, pathMatch: 'prefix'},
  {path: 'services/workflow-automation', component: ServiceFourComponent, pathMatch: 'prefix'},
  {path: 'blog', component: BlogComponent, pathMatch: 'prefix'},
  {path: 'blog-detail', component:BlogDetailComponent, pathMatch: 'prefix' },
  {path: 'work', component:WorkComponent, pathMatch: 'prefix' },
  {path: 'privacy-policy', component:PrivacyPolicyComponent, pathMatch: 'prefix' },
  {path: 'terms-condition', component:TermsConditionsComponent, pathMatch: 'prefix' },
  {path: 'case-study-page', component: CaseStudyPageComponent, pathMatch: 'prefix' },
  {path: '404', component: ErrorPageComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
