<div class="MainContent">
    <header>
      <app-header></app-header>
    </header>
    <section class="content">
      <app-contact-banner></app-contact-banner>
      <!-- <app-contact-map></app-contact-map> -->
    </section>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>