<div class="MainContent">
    <header>
        <app-header></app-header>
    </header>
    <section class="content">
        <div class="privacy-policy-banner">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 align-self-center">
                        <div class="leftContent">
                            <h1><strong>Privacy Policy</strong> </h1>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="rightContent">
                            <img class="" src="../../../../assets/blog/blog-banner.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="privacy-policy-content">
            <div class="container">
                <div class="privacy-section">
                    <h3>Privacy Policy</h3>
                    <p>At Devirsa, we value your privacy. Devirsa’s Privacy Policy includes the gathering, use and
                        disclosure of personal information that might be collected by Devirsa whenever you visit
                        Devirsa, e.g., when you visit our websites, when your buy our services, or when you contact our
                        sales and support associates (collectively, the “Service”).</p>
                    <p>This Privacy Policy is imposed only to data collected by Devirsa and doesn’t cover the data that
                        customers create and store with our products, including any personal data. You are requested to
                        stay updated with our Privacy Policy. If you feel that Devirsa doesn’t comply to this Policy,
                        feel free to contact Devirsa at <a href="mailto:legal@devirsa.com"
                            title="legal@devirsa.com">legal@devirsa.com</a>.</p>
                </div>
                <div class="privacy-section">
                    <h3>Why Personal Data is Collected by Us?</h3>
                    <p>The collection of your personal data lets us enhance your access to our services. Moreover, your
                        personal data helps us keep you familiar with latest service announcements, updates, special
                        offers and events that you might like to hear about.</p>
                    <h5 class="mt-4 mb-3">Data Collection and Use</h5>
                    <p>The collection of your personal data lets us enhance your access to our services. Moreover, your
                        personal data helps us keep you familiar with latest service announcements, updates, special
                        offers and events that you might like to hear about.</p>
                    <ul>
                        <li>You might be asked for your personal data when you’re discussing a problem over the phone
                            with our rep, signing up for a seminar, responding to an online survey, registering your
                            services, or opting for a service.</li>
                        <li>When you contact us, we may collect your personal data relevant to the situation, such as
                            your name, postal address, telephone number, email address business address, shipping
                            address and other contact preferences; your credit card information and information about
                            the Devirsa services you utilize, such as their serial numbers and date of purchase; and
                            information relating to a support or service issue.</li>
                        <li>We can also ask for your information for market research purposes—such as your job profile
                            and where your computer is utilized—to get a better insight of our consumers and thus offer
                            more valuable service or market our services.</li>
                        <li>If you opt for services through Devirsa, then we might collect payment info, including
                            credit card information, from you. We are committed protecting all of your financial data,
                            and we will never sell or share your data with any third party.</li>
                        <li>We collect information regarding customer’s activities on our websites. This helps us find
                            out how best to provide useful information to the users and to understand which parts of our
                            websites, services or products are most appealing to them and to market our services and
                            products.</li>
                    </ul>
                </div>
                <div class="privacy-section">
                    <h3>Sharing of Information on Public Platforms is Public</h3>
                    <p>If you share information through Devirsa forum, bulletin board or chat room on a Devirsa website, your data or information will be visible to others. Personally identifiable data you submit to one of these forums can be viewed, collected or used by other users.</p>
                    <p>Devirsa is not liable to the personally identifiable information you choose to share through or submit at these forums.</p>
                    <p>For example, if you choose to make information, which was previously non-public, available by enabling certain user features, Devirsa will collect that data from your interaction and the information will become publicly available.</p>
                </div>
                <div class="privacy-section">
                    <h3>When We Disclose Your Information</h3>
                    <p>We are committed to protecting your data with us.</p>
                    <p>We do not share, rent or trade your personal information with other company for any marketing purposes. However, we may share it with any third party which draws a legally compliant request of its disclosure.</p>
                    <h5 class="mt-4 mb-3">Within the Devirsa Group</h5>
                    <p>To improve our services, your personal data might be shared with legal entities within the Devirsa group globally who will take steps to protect as mentioned in the Devirsa’s privacy policy.
                    </p>
                    <h5 class="mt-4 mb-3">With our Service Providers, Vendors, and Strategic Partners:</h5>
                    <p>We may provide your personal information to our partners and affiliates in order to detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of our services; and to enable joint or co-branded services that you request where such services are provided by multiple businesses. These corporate entities and affiliates may not market their services without your consent. We may disclose your personal information if required by a law, state and law enforcement agencies.</p>
                    <p>Our service providers and affiliates can help us process data information, extend credit, fulfill customer orders, deliver products to you, manage and enhance customer data, provide customer service, assess your interest in our services, market our services, or conduct customer research or satisfaction surveys.</p>
                    <p>They are also bound to protect your personal data as mentioned in our Devirsa’s policies, except if we inform you otherwise at the time of collection.</p>
                    <p>Without such data being made available, it would be challenging for you to subscribe for services, have services provided to you, get customer support, and provide us feedback to improve the quality of our services and products, offers and content on our website.</p>
                    <p>We are required to cooperate with law enforcement agencies when it comes to identifying those who utilize the Service for illegal activities. Therefore, we have to respond to subpoenas, warrants or other court orders regarding data concerning any consumers.</p>
                    <p>We may disclose your personal information if required by a law, state and law enforcement agencies.</p>
                </div>
                <div class="privacy-section">
                    <h3>How We Protect your Personal Information</h3>
                    <p>Devirsa deployes administrative, electronic, and physical measures to secure your confidential details from negligence, fraud , and abuse, and unwanted entry, release, modification, and damage. We use security mechanisms, such as firewalls and Secure Socket Layers ("SSL"), to maintain the confidentiality of the personal identifiable information about our users.</p>
                    <p>Data we received from our consumers is stored on secured servers. This is important to protect your sensitive data associated with your personal and credit card information while it is traveled over the Internet.</p>
                    <p>However, you are advised to follow the safe online practices to protect personal data. Use strong passwords and change them often by using a combination of letters and numbers, and make sure you use a secure Web browser.</p>
                    <p>However, there is no security that’s not vulnerable. You can get your doubts cleared about the security of the Service by contacting us at <a href="mailto:legal@devirsa.com" title="legal@devirsa.com">legal@devirsa.com</a>.</p>
                    <h5 class="mt-4 mb-3">To Inspect, Change or Modify your Personal Information</h5>
                    <p>You can request the readable copy of your personal data from Devirsa. All you need to submit the proof of your identity before doing so. Devirsa will also change the data that you have provided to us. Any such request should be sent by email to <a href="mailto:cservice@devirsa.com" title="cservice@devirsa.com">cservice@devirsa.com</a>.</p>
                    <h5 class="mt-4 mb-3">Minors</h5>
                    <p>We don’t intend to collect personal information from minors (individuals below the age of 18) and no part of the Service is meant for the minors.</p>
                    <p>We don’t intend to provide any data about minors or market to minor. If minor provide Devirsa with their personal data and we learn that the data is of a minor, we are dedicated to delete the data as soon as possible. At the time the minor’s data is received it will be subject to the action mentioned by the Devirsa Privacy Policy.</p>
                </div>
                <div class="privacy-section">
                    <h3>Cookies and other Technologies</h3>
                    <p>A Cookie is a piece of data placed on your HDD or hard disk drive carrying non-personally identifiable information the user. When a user visits the site, we place one or more cookies/gif files to locate the IP address and identity. We send cookies to improve our site preferences and understand your needs better so that we cater your preferences. You can decline the cookies by making small setting given in your browser.</p>
                    <p>If you reject or turn off the cookies, you can use the Service but you might be limited in some areas of the Service. For example, you will not be able to participate in any of our sweepstakes, contests or drawings that we may offer.</p>
                    <p>As is true of most websites, we gather certain information automatically and store it in log files. This information includes Internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), referring/exit pages, operating system, date/time stamp and click stream data. We use this information, which does not identify individual users, to analyze trends, to administer the site, to track users’ movements around the site and to gather demographic information about our user base as a whole. Devirsa may use the information collected to market directly to that person.</p>
                    <p>Some of our email messages contain a “click-through URL” linked to content on the Devirsa website. These links take the customer to our web server before reaching at the destination web page. We keep track of this click-through data to determine interest in particular topics and measure the efficiency of our customer communications. You can avoid clicking text or graphic links in the email if you don’t want to be tracked.</p>
                    <p>Besides, pixel tags or tiny graphic images can be used to determine what parts of our website are visited by customer or to determine the efficiency of searches customers perform on the site.</p>
                    <p>Pixel tags also let us send email messages in a format being readable to the customers. And they notify us whether emails have been opened to assure that we are only sending messages that are of interest to our customers. We might use this data to reduce or eliminate messages delivered to the users.</p>
                </div>
                <div class="privacy-section">
                    <h3>Data retention</h3>
                    <p>We will retain your personal data for some time necessary to meet the objectives mentioned in this Privacy Policy unless a longer retention period is needed or required by law, for legal, tax or regulatory reasons, or other lawful reasons.</p>
                    <p>In condition of having no ongoing legitimate business require to process your personal data, we will either delete or anonymize it or, if this is not possible (for example, because your personal data has been stored in backup archives), then your data will be stored discreetly and kept away from any further processing until deletion is last resort.</p>
                </div>
                <div class="privacy-section">
                    <h3>Legal basis for processing your personal information</h3>
                    <p>Our legitimate reasons for gathering and utilizing the personal data outlined in this Privacy Policy depend on the personal data concerned and the particular circumstance in which we gather it.</p>
                    <p>Generally, we collect personal data from you only when you allow us to do so, where we require the personal data to create a contract with you, or where the processing is in our legal business interests. In some scenarios, we might also have a legal duty to ask for your personal data.</p>
                    <p>If we require your personal data to abide by the law or to create a contract with you, we will explain this and recommend you if the provision of your personal data is compulsory or not (as well as of the possible penalty if you do not share your personal data with us).</p>
                    <p>Likewise, if we collect and utilize your personal data in reliance on our legitimate business interests, we will make clear to you at the applicable time what those legitimate business interests are. Common instances include collecting and keeping data to document a sales transaction or customer service requested.</p>
                    <p>If you have queries or require more information concerning the legal basis on which we collect and use your personal data, feel free to contact us at <a href="mailto:legal@devirsa.com" title="legal@devirsa.com">legal@devirsa.com</a>.</p>
                </div>
                <div class="privacy-section">
                    <h3>Your data protection rights & choices</h3>
                    <p>You have the following rights:</p>
                    <ul>
                        <li>Asking for the access to data that we collect about you.</li>
                        <li>Correction, update and request for the removal of your personal information.</li>
                        <li>Objecting to processing or your personal data. You can ask us to limit processing of your personal data or request portability of your personal information where it is technically possible.</li>
                        <li>Withdrawing of consent any time.</li>
                        <li>Complaining to a data protection authority about the collection and use of your personal data.</li>
                    </ul>
                    <p>You can unsubscribe from marketing communications we send you at any time. You can practice this right by clicking on the “unsubscribe” link in the marketing e-mails we send you or by contacting us at <a href="mailto:cservice@devirsa.com" title="cservice@devirsa.com">cservice@devirsa.com</a>.</p>
                    <p>We try to respond to all queries or requests being sent by customers looking to practice their data protection rights according to the applicable data protection laws. You can also contact us for the same at <a href="mailto:cservice@devirsa.com" title="cservice@devirsa.com">cservice@devirsa.com</a>. We can extend the rights to all consumers, irrespective of their location.</p>
                </div>
                <div class="privacy-section">
                    <h3>Our Company-wide Commitment to your Privacy</h3>
                    <p>To protect your personal data with us, we explain these guidelines to Devirsa employees and strictly impose privacy safeguards within the company. Moreover, we promote industry initiatives to preserve privacy rights on the Internet and in all aspects of online business.</p>
                    <p>Our website is lined with the sites of other companies. We are responsible for their practices or processes associated with privacy and data security. Therefore, you are advised to read their privacy policies carefully.</p>
                </div>
                <div class="privacy-section">
                    <h3>Privacy Questions:</h3>
                    <p>If you have queries or concerns about our customer Privacy Policy or data processing, contact us at <a href="mailto:legal@devirsa.com" title="legal@devirsa.com">legal@devirsa.com</a>. If you don’t want to be updated by Devirsa on news, software and product information and services, you can request us to remove your name from our list records for mailing. Such requests can be sent by email to <a href="mailto:cservice@devirsa.com" title="cservice@devirsa.com">cservice@devirsa.com</a>.</p>
                </div>
                <div class="privacy-section">
                    <h3>Your Consent and Notification of Changes</h3>
                    <p>Opening or Using Devirsa will be accepted as your consent to be agreed with our Privacy Policy. Our Privacy Policy is subject to change without sending you any prior notice. Therefore, you are request to read our Privacy Policy from time to time to stay updated with the policy.</p>
                </div>
            </div>
        </div>

    </section>
    <footer>
        <app-footer></app-footer>
    </footer>
</div>