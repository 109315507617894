<div class="service-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 align-self-center">
                <div class="leftContent">
                    <h1>We help digital-savvy businesses to operate, transform, and optimize their<strong> business processes effectively and seamlessly </strong> with our process automation services.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="img-top">
    <div class="container">
        <div class="img-top-cont">
            <img src="../../../../../../assets/workflow-automation/banner-top.png" alt="">
        </div>
    </div>
</div>
