<div class="service-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 align-self-center">
                <div class="leftContent">
                    <h1>We take a holistic view of your target users’ requirements and <strong> design growth-driving digital products.
                    </strong></h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="img-top">
    <div class="container">
        <div class="img-top-cont">
            <img src="../../../../../../assets/ui-ux/banner-top.png" alt="">
        </div>
    </div>
</div>
