<div class="blog-content">
    <div class="container">
        <div class="topSect">
            <div class="leftContent">
                <h6>Blogs & News</h6>
                <h2>Our Latest<strong> Digital Insights</strong>
                </h2>
            </div>
            <button routerLinkActive="active" routerLink="/blog" class="btn custom-btn">View all insights</button>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="newsContent">
                    <a href="javascript:void(0)"><img src="../../../../assets/news_img_1.png" alt=""></a>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/blog-detail" class="newsHeading">
                        Donec dapibus mauris id odio ornare tempus.
                    </a>
                    <div class="authDetails">
                        <span>Kathryn Reynolds.</span>
                        <span>Feb 28, 2020</span>
                    </div>
                    <p class="desc">
                        Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.
                    </p>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/blog-detail"><span>Learn More</span></a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="newsContent">
                    <a href="javascript:void(0)"><img src="../../../../assets/news_img_2.png" alt=""></a>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/blog-detail" class="newsHeading">
                        Donec dapibus mauris id odio ornare tempus.
                    </a>
                    <div class="authDetails">
                        <span>Kathryn Reynolds.</span>
                        <span>Feb 28, 2020</span>
                    </div>
                    <p class="desc">
                        Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.
                    </p>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/blog-detail"><span>Learn More</span></a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="newsContent">
                    <a href="javascript:void(0)"><img src="../../../../assets/news_img_3.png" alt=""></a>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/blog-detail" class="newsHeading">
                        Donec dapibus mauris id odio ornare tempus.
                    </a>
                    <div class="authDetails">
                        <span>Kathryn Reynolds.</span>
                        <span>Feb 28, 2020</span>
                    </div>
                    <p class="desc">
                        Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.
                    </p>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/blog-detail"><span>Learn More</span></a>
                </div>
            </div>
        </div>
    </div>
</div>