<div class="our-approach-content">
    <div class="container">
        <div class="stackContent">
            <h6>Our Approach</h6>
            <h2> Our client-driven, <strong>Agile Methodology </strong> helps engineer <br>digital products with a <strong> faster-time-to-market</strong>. </h2>
        </div>
    </div>
    <div class="timeline-box">
        <div class="container">
            <img src="../../../../assets/timeline/timeline-1920.png" alt="" class="timeline_1920">
            <img src="../../../../assets/timeline/timeline-mobile.png" alt="" class="timeline_mobile">
        </div>
    </div>
</div>