import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  createFormGroup() {
    return new FormGroup({
      reply: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      name: new FormControl('', Validators.required)
    });
  }

  formGroup: FormGroup;

  constructor() {
    this.formGroup = this.createFormGroup();
  }

  ngOnInit(): void {
  }

  submitForm() {
    // this.formGroup.valid;
    console.log(this.formGroup.value);
    this.formGroup = this.createFormGroup();
  }

}
