<div class="work-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <h1> We have more <strong> success <br> stories</strong> for you</h1>
                    <p>Some digital agencies design gratifying user experiences, but outsource development. Others have
                        expertise in innovating web and mobile solutions.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightContent">
                    <img class="" src="../../../../assets/blog/blog-banner.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="work-content">
    <div class="container">
        <div class="counter-sect">
            <h3>Experience that make <strong>an Impact</strong></h3>
            <p>We bring a diverse team of experienced designers and developers to your project, aligning your business needs and goals with your users’ need for a friendly, intuitive, delightful experience. We’re your partner in digitally driven success.</p>
        </div>
    </div>
</div>