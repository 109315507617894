<div class="sliderContent">
    <div class="container">
        <section class="slideBox">
            <div class="slideContentTop">
                <h6>Testimonials</h6>
                <h2><strong> We don’t need VOTES </strong>
                </h2>
                <h3>We have great feedback that proves we are our client's favorite</h3>
            </div>
            <div class="container customSlider">
                <div id="clientSlider" class="carousel slide position-relative" data-ride="carousel" data-interval="6000">
                    <!-- Indicators -->
                    <!-- <ul class="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" class=""></li>
                        <li data-target="#demo" data-slide-to="1" class="active"></li>
                        <li data-target="#demo" data-slide-to="2" class=""></li>
                    </ul> -->
                    <!-- The slideshow -->
                    <div class="carousel-inner">
                        <div class="carousel-item active ">
                            <div class="d-flex">
                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column">
                                    <div class="introduce pb-3">
                                        <div class="flex-column-1 ">
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                            <p class="">We used DeVirsa to help build a scalable SaaS based solution to sell our construction and transportation equipment. Their architect and development teams were great to work with.  They were able to consume our requirements and synthesize them into a product we could have never developed on our own. YOu could not ask for a better partner in developing your own product.</p>
                                        </div>
                                        <div class="flex-column-2 d-flex">
                                            
                                            <div class="information  flex-column pt-2">
                                                <h6> Bill Kaigler</h6>
                                                <p class="text-gray">VP Technology, Terra Point LLC</p>
                                            </div>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column">
                                    <div class="introduce pb-3">
                                        <div class="flex-column-1 ">
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                            <p class="">I love the three things that Devirsa introduce to their product development process - Empathy, Engineer, and Experience. Their understanding of experience-driven digital product development helped us reach our target audience in a seamless and result-driven manner. Kudos to the entire team.</p>
                                        </div>
                                        <div class="flex-column-2 d-flex">
                                            
                                            <div class="information  flex-column pt-2">
                                                <h6>Bobson Groves</h6>
                                                <!-- <p class="text-gray">Managing Director</p> -->
                                            </div>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column">
                                    <div class="introduce pb-3">
                                        <div class="flex-column-1 ">
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                            <p class="">Devirsa provided us an excellent support in the areas of experience design and digital transformation. I can confidently recommend Devirsa as your digital transformation enabler who are hard-working and dedicated in their field.</p>
                                        </div>
                                        <div class="flex-column-2 d-flex">
                                            
                                            <div class="information  flex-column pt-2">
                                                <h6>Mike Evans</h6>
                                                <!-- <p class="text-gray">Managing Director</p> -->
                                            </div>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="carousel-item">
                            <div class="d-flex">
                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column">
                                    <div class="introduce pb-3">
                                        <div class="flex-column-1 ">
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                            <p class="">We used DeVirsa to help build a scalable SaaS based solution to sell our construction and transportation equipment. Their architect and development teams were great to work with.  They were able to consume our requirements and synthesize them into a product we could have never developed on our own. YOu could not ask for a better partner in developing your own product.</p>
                                        </div>
                                        <div class="flex-column-2 d-flex">
                                            
                                            <div class="information  flex-column pt-2">
                                                <h6> Bill Kaigler</h6>
                                                <p class="text-gray">VP Technology, Terra Point LLC</p>
                                            </div>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column">
                                    <div class="introduce pb-3">
                                        <div class="flex-column-1 ">
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                            <p class="">I love the three things that Devirsa introduce to their product development process - Empathy, Engineer, and Experience. Their understanding of experience-driven digital product development helped us reach our target audience in a seamless and result-driven manner. Kudos to the entire team.</p>
                                        </div>
                                        <div class="flex-column-2 d-flex">
                                            
                                            <div class="information  flex-column pt-2">
                                                <h6>Bobson Groves</h6>
                                                <!-- <p class="text-gray">Managing Director</p> -->
                                            </div>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column">
                                    <div class="introduce pb-3">
                                        <div class="flex-column-1 ">
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                            <p class="">Devirsa provided us an excellent support in the areas of experience design and digital transformation. I can confidently recommend Devirsa as your digital transformation enabler who are hard-working and dedicated in their field.</p>
                                        </div>
                                        <div class="flex-column-2 d-flex">
                                            
                                            <div class="information  flex-column pt-2">
                                                <h6>Mike Evans</h6>
                                                <!-- <p class="text-gray">Managing Director</p> -->
                                            </div>
                                        </div>
                                        <span></span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                    </div>

                    <!-- Left and right controls -->
                    <div class="slideControls">
                        <a class="carousel-control-prev" href="#clientSlider" data-slide="prev" style="opacity: 1; left: -10%;">
                            <img src="../../../../assets/arrow-left.svg" alt="">
                        </a>
                        <a class="carousel-control-next" href="#clientSlider" data-slide="next"
                            style="opacity: 1; transform: rotate(-180deg); right: -10%;">
                            <img src="../../../../assets/arrow-left.svg" alt="">
                        </a>
                    </div>
                </div>



            </div>


        </section>
    </div>
</div>