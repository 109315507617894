<div class="address-content">
    <div class="container">
        <div class="addressBox">
            <img src="../../../../assets/address-icon.svg" alt="">
            <div class="rytContent">
                <h3>Address</h3>
                <p>8811 Teel Pkwy Ste 100-5715, Frisco, TX 75035, United States</p>
            </div>
        </div>
        <div class="addressBox">
            <img src="../../../../assets/mail-icon.svg" alt="">
            <div class="rytContent">
                <h3>Email</h3>
                <!-- <a href= "mailto:support@rivercitycorp.in">support@rivercitycorp.in</a> -->
                <a href= "mailto:contact@devirsa.com">contact@devirsa.com</a>
            </div>
        </div>
        <!-- <div class="addressBox">
            <img src="../../../../assets/phone-icon.svg" alt="">
            <div class="rytContent">
                <h3>Phone</h3>
                <a href="tel:+65 8534 2145">+65 8534 2145</a>
                <a href="tel:+44 8534 2145">+44 8534 2145</a>
            </div>
        </div> -->
    </div>
</div>