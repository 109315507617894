<div class="join-team-content">
    <div class="container">
        <div class="counter-sect">
            <h6>Insights</h6>
            <!-- <h2>Wish to join the <strong>team?</strong></h2> -->
            <p>Stay ahead of the learning curve by getting the latest digital insights, straight to your inbox, shaping
                the business environment.
            </p>
            <button class="btn custom-btn-green" type="submit" (click)="subscribeUs()">Subscribe Us</button>
            <!-- <input type="email" placeholder="Enter Your Email ID" #email class="form-control formText"
                            formControlName="email" required> -->
        </div>
    </div>
</div>