import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { SliderComponent } from './Pages/Home/slider/slider.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './common/navigation/nav/nav.component';
import { BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap';
import { BannerComponent } from './Pages/Home/banner/banner.component';
import { AboutComponent } from './Pages/Home/about/about.component';
import { ServicesComponent } from './Pages/Home/services/services.component';
import { CaseStudyComponent } from './Pages/Home/case-study/case-study.component';
import { BlogsComponent } from './Pages/Home/blogs/blogs.component';
import { ImageSliderComponent } from './Pages/Home/image-slider/image-slider.component';
import { AboutUsComponent } from './Pages/about-us/about-us.component';
import { AboutBannerComponent } from './Pages/about-us/about-banner/about-banner.component';
import { HomeComponent } from './Pages/Home/home.component';
import { AboutServiceComponent } from './Pages/about-us/about-service/about-service.component';
import { AboutSliderComponent } from './Pages/about-us/about-slider/about-slider.component';
import { AboutGoalComponent } from './Pages/about-us/about-goal/about-goal.component';
import { AchievementsComponent } from './Pages/Home/achievements/achievements.component';
import { JoinTeamComponent } from './Pages/about-us/join-team/join-team.component';
import { ChooseUsComponent } from './Pages/about-us/choose-us/choose-us.component';
import { ImageGalleryComponent } from './Pages/about-us/image-gallery/image-gallery.component';
import { OurServicesComponent } from './Pages/our-services/our-services.component';
import { ServiceBannerComponent } from './Pages/our-services/service-banner/service-banner.component';
import { CompanyServiceListComponent } from './Pages/Home/services/company-service-list/company-service-list.component';
import { ServiceListsComponent } from './Pages/our-services/service-lists/service-lists.component';
import { GetStartedComponent } from './Pages/our-services/get-started/get-started.component';
import { TechnologyStackComponent } from './Pages/our-services/technology-stack/technology-stack.component';
import { ContactUsComponent } from './Pages/contact-us/contact-us.component';
import { ContactBannerComponent } from './Pages/contact-us/contact-banner/contact-banner.component';
import { ContactFormComponent } from './Pages/contact-us/contact-form/contact-form.component';
import { ContactAddressComponent } from './Pages/contact-us/contact-address/contact-address.component';
import { ContactMapComponent } from './Pages/contact-us/contact-map/contact-map.component';
import { OurApproachComponent } from './Pages/our-services/our-approach/our-approach.component';
import { ServiceDetailBannerComponent } from './Pages/our-services/all-services/service-one/service-detail-banner/service-detail-banner.component';
import { SDetailContentComponent } from './Pages/our-services/all-services/service-one/s-detail-content/s-detail-content.component';
import { BlogComponent } from './Pages/blog/blog.component';
import { BlogBannerComponent } from './Pages/blog/blog-banner/blog-banner.component';
import { BlogDetailComponent } from './Pages/blog/blog-detail/blog-detail.component';
import { WorkComponent } from './Pages/work/work.component';
import { WorkBannerComponent } from './Pages/work/work-banner/work-banner.component';
import { WorkFilterComponent } from './Pages/work/work-filter/work-filter.component';
import { TooltipComponent } from './Pages/Directives/tooltip/tooltip.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceOneComponent } from './Pages/our-services/all-services/service-one/service-one.component';
import { ServiceTwoComponent } from './Pages/our-services/all-services/service-two/service-two.component';
import { ServiceThreeComponent } from './Pages/our-services/all-services/service-three/service-three.component';
import { ServiceFourComponent } from './Pages/our-services/all-services/service-four/service-four.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { PrivacyPolicyComponent } from './Pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './Pages/terms-conditions/terms-conditions.component';
import { CaseStudyPageComponent } from './Pages/case-study-page/case-study-page.component';
import { CaseBannerComponent } from './Pages/case-study-page/case-banner/case-banner.component';
import { ErrorPageComponent } from './Pages/error-page/error-page.component';
import { ServiceTwoBannerComponent } from './Pages/our-services/all-services/service-two/service-two-banner/service-two-banner.component';
import { ServiceTwoContentComponent } from './Pages/our-services/all-services/service-two/service-two-content/service-two-content.component';
import { ServiceThreeBannerComponent } from './Pages/our-services/all-services/service-three/service-three-banner/service-three-banner.component';
import { ServiceThreeContentComponent } from './Pages/our-services/all-services/service-three/service-three-content/service-three-content.component';
import { ServiceFourContentComponent } from './Pages/our-services/all-services/service-four/service-four-content/service-four-content.component';
import { ServiceFourBannerComponent } from './Pages/our-services/all-services/service-four/service-four-banner/service-four-banner.component';
import { ContactSectionComponent } from './Pages/Home/contact-section/contact-section.component';
import { ServicesNavComponent } from './Pages/our-services/services-nav/services-nav.component';
import { UsasecComponent } from './Pages/usasec/usasec.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    NavComponent,
    BannerComponent,
    AboutComponent,
    ServicesComponent,
    CaseStudyComponent,
    BlogsComponent,
    ImageSliderComponent,
    AboutUsComponent,
    AboutBannerComponent,
    AboutServiceComponent,
    AboutSliderComponent,
    AboutGoalComponent,
    AchievementsComponent,
    JoinTeamComponent,
    ChooseUsComponent,
    ImageGalleryComponent,
    OurServicesComponent,
    ServiceBannerComponent,
    CompanyServiceListComponent,
    ServiceListsComponent,
    GetStartedComponent,
    TechnologyStackComponent,
    ContactUsComponent,
    ContactBannerComponent,
    ContactFormComponent,
    ContactAddressComponent,
    ContactMapComponent,
    OurApproachComponent,
    ServiceDetailBannerComponent,
    SDetailContentComponent,
    BlogComponent,
    BlogBannerComponent,
    BlogDetailComponent,
    WorkComponent,
    WorkBannerComponent,
    WorkFilterComponent,
    TooltipComponent,
    ServiceOneComponent,
    ServiceTwoComponent,
    ServiceThreeComponent,
    ServiceFourComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    CaseStudyPageComponent,
    CaseBannerComponent,
    ErrorPageComponent,
    ServiceTwoBannerComponent,
    ServiceTwoContentComponent,
    ServiceThreeBannerComponent,
    ServiceThreeContentComponent,
    ServiceFourContentComponent,
    ServiceFourBannerComponent,
    ContactSectionComponent,
    ServicesNavComponent,
    UsasecComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    BsDropdownModule.forRoot(),AlertModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
