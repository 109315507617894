<div class="image-gallery-content">
    <div class="row">
        <div class="col-md-6">
            <div class="imgBoxLeft">
                <div class="boxContainer boxLarge">
                    <img src="../../../../assets/gallery_image1.png" alt="" class="img-responsive">
                    <div class="boxContent">
                        <h4>Experience-Led Engineering
                        </h4>
                        
                    </div>
                </div>
                <div class=" boxContainer boxSmall">
                    <img src="../../../../assets/gallery_image2.png" alt="" class="img-responsive">
                    <div class="boxContent">
                        <h4>Agile Methodology</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="imgBoxLeft imgBoxRight">
                <div class=" boxContainer boxSmall">
                    <img src="../../../../assets/gallery_image3.png" alt="" class="img-responsive">
                    <div class="boxContent">
                        <h4>Cross-Industry Expertise</h4>
                    </div>
                </div>
                <div class="boxContainer boxLarge">
                    <img src="../../../../assets/gallery_image1.png" alt="" class="img-responsive">
                    <div class="boxContent">
                        <h4>Customer-First Approach</h4>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>