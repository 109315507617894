<div class="contact-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <h1>Let’s get <strong>Connected </strong></h1>
                    <h2><i>No Strings Attached!</i></h2>
                    <p>Please fill the form below so we can direct your inquiry to the right team and get back to you as early as possible. 
                    </p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="rightContent">
                    <img class="" src="../../../../assets/contact-banner.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-mid">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <app-contact-form></app-contact-form>
            </div>
            <div class="col-md-6 align-self-center">
                <app-contact-address></app-contact-address>
            </div>
        </div>
    </div>
</div>