<div class="container">
    <div class="filter-section">
        <div class="leftHeading">
            <h3>Microsoft CRM Portals</h3>
        </div>
        <div class="rightFilters">
            <span>See our work:</span>
            <div class="select-container">
                <select name="" id="">
                    <option value="Option 1">All Industry</option>
                    <option value="Option 2">Option 2</option>
                    <option value="Option 3">Option 3</option>
                </select>

                <!-- <div ngbDropdown class="d-inline-block">
                    <button class="custom-select-comp" id="dropdownBasic1" ngbDropdownToggle>All Industries</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem>Action - 1</button>
                        <button ngbDropdownItem>Another Action</button>
                        <button ngbDropdownItem>Something else is here</button>
                    </div>
                </div> -->

            </div>
            <div class="select-container">
                <select name="" id="">
                    <option value="Option 1">Microsoft CRM Portals</option>
                    <option value="Option 2">Option 2</option>
                    <option value="Option 3">Option 3</option>
                </select>
            </div>


        </div>
    </div>

    <div class="grid">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-1.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-2.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-3.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-4.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-5.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-6.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-7.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-8.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="work-grid">
                        <img src="../../../../assets/work/work-9.png" alt="" class="work-img">
                        <a href="javascript:void(0)" class="work-heading">Donec dapibus mauris id odio ornare tempus.</a>
                        <p>Lorem ipsum dolor sit met, wrg consetetur sadipscing elitr, sed didfam nonumy emo.</p>
                        <a href="javascript:void(0)"><span>Learn More</span></a>
                    </div>
                </div>
            </div>
            <nav class="custom-pagination">
                <ul class="pagination justify-content-center">
                    <li class="page-item previous disabled"><a class="page-link" href="#" aria-label="Previous"><i
                                class="fa fa-angle-left"></i></a></li>
                    <li class="page-item active"><a class="page-link" href="#">1 <span
                                class="sr-only">(current)</span></a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item next"><a class="page-link" href="#" aria-label="Next"><i
                                class="fa fa-angle-right"></i></a></li>
                </ul>
            </nav>

            <!-- <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination> -->
        </div>
    </div>
</div>