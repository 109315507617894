import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {



  constructor() {

    $(function () {

      var link = $('#navbar a.dot');

      // Move to specific section when click on menu link
      link.on('click', function (e) {
        var target = $($(this).attr('href'));
        $('html, body').animate({
          scrollTop: target.offset().top - 20
        }, 600);
        $(this).addClass('active labelName');
        e.preventDefault();
      });

      // Run the scrNav when scroll
      $(window).on('scroll', function () {
        scrNav();
        // $(link).removeClass('labelName');
        // e.preventDefault();
      });

      // scrNav function 
      // Change active dot according to the active section in the window
      function scrNav() {
        var sTop = $(window).scrollTop();
        $('section').each(function () {
          var id = $(this).attr('id'),
            offset = $(this).offset().top - 45,
            height = $(this).height();
          if (sTop >= offset && sTop < offset + height) {
            link.removeClass('active');
            $('#navbar').find('[data-scroll="' + id + '"]').addClass('active');
            

          }
        });
      }
      scrNav();
    });
   
  }

  ngOnInit(): void {
  }
  

}
