<div class="service-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 align-self-center">
                <div class="leftContent">
                    <h1>Our <strong> Case Study</strong>.</h1>
                    <p>Get the ultimate buy-in for your brand with eCommerce solutions that exceed your <br> customers’ expectations.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="img-top">
    <div class="container">
        <div class="img-top-cont">
            <img src="../../../../../assets/service-detail-top.png" alt="">
        </div>
    </div>
</div>
