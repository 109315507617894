import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-banner',
  templateUrl: './work-banner.component.html',
  styleUrls: ['./work-banner.component.scss']
})
export class WorkBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
