<div class="choose-us-content">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 align-self-center">
                <div class="leftContent">
                    <h6>Why choose us</h6>
                    <h2>We build <strong> experiences </strong> that matter. 
                       </h2>
                    <p>Our customer-driven, agile methodology helps engineer digital products with a faster-time-to-market, delivering your digital domination.
                    </p>
                        <!-- <ul class="collapse" id="chooseCollapse">
                            <li>Experience-Led Engineering
                            </li>
                            <li>Agile Methodology
                            </li>
                            <li>Cross-Industry Expertise
                            </li>
                            <li>Customer-First Approach
                            </li>
                        </ul> -->
                    <!-- <button class="btn custom-btn mt-3" type="button" data-toggle="collapse" data-target="#chooseCollapse" aria-expanded="false" aria-controls="chooseCollapse">Know more</button> -->
                </div>
            </div>
            <div class="col-lg-8">
                <div class="rightContent">
                    <app-image-gallery></app-image-gallery>
                </div>
            </div>

        </div>
    </div>
</div>
