<div class="case-study-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <h6>EXPLORE OUR WORK</h6>
                    <h2><strong>We Build and Grow Digital Ideas, Together.</strong></h2>
                    <p>92% of our truly innovative clients recommend us because we solve their complex digital problems through our unique and straightforward solutions.</p>
                    <button routerLinkActive="active" routerLink="/case-study-page" class="btn custom-btn">Read Client Success Stories</button>
                </div>
            </div>
            <div class="col-md-6">
                <div class="contentCarousel">
                    <app-image-slider></app-image-slider>
                </div>
            </div>

        </div>
    </div>
</div>
