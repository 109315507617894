<div class="service-details">
        <div class="row">
            <div class="col-md-6 col-12 mb-4">
                <div class="contentBox">
                    <div class="leftContent">
                        <div class="box">
                            <img src="../../../../../assets/cloud-migration-small.png" alt="">
                        </div>
                    </div>
                    <div class="rightContent">
                        <h4>Cloud Service</h4>
                        <p>Embrace Cloud Services to uncover intelligence, innovation, and true business value.</p>
                        <a href="javascript:void(0)" routerLinkActive="active" routerLink="/services/cloud-service"><span>Learn More</span></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 mb-4">
                <div class="contentBox">
                    <div class="leftContent">
                        <div class="box"><img src="../../../../../assets/custom-aap-dev-small.png" alt=""></div>
                    </div>
                    <div class="rightContent">
                        <h4>Custom App Development</h4>
                        <p>Accelerate with experience-led custom applications, defining a new style of Software
                            Development.</p>
                        <a href="javascript:void(0)" routerLinkActive="active" routerLink="/services/custom-app-development"><span>Learn More</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-12 mb-4">
                <div class="contentBox">
                    <div class="leftContent">
                        <div class="box"><img src="../../../../../assets/ui-ux-exp-small.png" alt=""></div>
                    </div>
                    <div class="rightContent">
                        <h4>User Interface & User Experience</h4>
                        <p>Experience the engaging product design that is attractive, functional, and easy-to-use.</p>
                        <a href="javascript:void(0)" routerLinkActive="active" routerLink="/services/ui-ux"><span>Learn More</span></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 mb-4">
                <div class="contentBox">
                    <div class="leftContent">
                        <div class="box"><img src="../../../../../assets/explainer-video-small.png" alt=""></div>
                    </div>
                    <div class="rightContent">
                        <h4>Workflow Automation</h4>
                        <p>Empathize and Engage your potential customers by telling your story with a
                            professional Explainer Video.</p>
                        <a href="javascript:void(0)" routerLinkActive="active" routerLink="/services/workflow-automation"><span>Learn More</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>