<div class="bannerContent">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <h1>We are <strong>devirsa</strong></h1>
                    <p>Secure your digital roots, under a young and experienced roof. We drive sustainable business innovation and agility with our cutting-edge, enterprise-grade, next-gen digital solutions.
                    </p>
                    <!-- <div class="btns-box">
                        <button data-toggle="modal" data-target="#videoModal" class="btn no-bg">
                            <i class="fa fa-play"></i> Watch the video to learn <br>more about us
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightContent">
                    <img class="" src="../../../../assets/aboutBannerImg.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="counterSect">
    <div class="container">
        <div class="counter-sect">
            <h6>Our Mission</h6>
            <h2>Mission for <strong>Digital Evolution</strong></h2>
            <p>To help businesses innovate and accelerate the acquisition of trending technologies, unwinding complex issues - through our technology-driven culture and excellence - that emerge during digital evolution.
            </p>
            <p>This statement is an ultimate KPI against which we measure our success. The said mission is ingrained in the thoughts of every Devirsian and our behavior and results demonstrate that. 
            </p>
        </div>
    </div>
</div>



<div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <a class="modal-close" href="" data-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i></a>
                <!-- <iframe width="100%" height="315" src="https://www.youtube.com/embed/pWOv9xcoMeY?start=1" frameborder="0" allow=" autoplay;" allowfullscreen></iframe> -->
                <video width="100%" controls="true" autoplay>
                    <source src="../../../../assets/sample-video.mp4" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</div>