<div class="errorContent desktop-ver">
   <div class="container-fluid">
    <div class="row">
        <div class="col-md-6 no-pad-left">
            <div class="leftContent">
                <a href="" routerLinkActive="active" routerLink="/home" class="logo">
                    <img src="./../../../assets/main-logo.svg" alt="">
                </a>
                <div class="content">
                    <p>
                        Looks like <br>
                        <span class="text-green">this page is moved</span> <br>
                        or never exists...
                    </p>
                    <button routerLinkActive="active" routerLink="/home" class="btn btn-primary">
                        Go to home
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6 no-pad-right">
            <!-- <img src="../../../assets/404 error page.jpg" alt=""> -->
        </div>
       </div>
   </div>
</div>


<div class="errorContent mobile-ver no-bg">
    <div class="container-fluid">
     <div class="row">
         <div class="col-xl-6 no-pad-left">
             <div class="leftContent">
                 <a href="" routerLinkActive="active" routerLink="/home" class="logo">
                     <img src="./../../../assets/main-logo.svg" alt="">
                 </a>
                 <div class="content">
                     <p>
                         Looks like <br>
                         <span class="text-green">this page is moved</span> <br>
                         or never exists...
                     </p>
                     <button routerLinkActive="active" routerLink="/home" class="btn btn-primary">
                         Go to home
                     </button>
                 </div>
             </div>
         </div>
         <div class="col-xl-6 no-pad-right no-pad-left">
             <div class="img-box">
                <img src="../../../assets/404-error-page-mobile.jpg" alt="">
             </div>
         </div>
        </div>
    </div>
 </div>