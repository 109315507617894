<div class="service-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 align-self-center">
                <div class="leftContent">
                    <h1>We give shape to your ideas by building successful <strong> custom apps for a transformative experience.
                    </strong></h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="img-top">
    <div class="container">
        <div class="img-top-cont">
            <img src="../../../../../../assets/custom-app-development/banner-top.png" alt="">
        </div>
    </div>
</div>
