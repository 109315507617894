<div class="technology-stack-content">
    <div class="container">
        <div class="stackContent">
            <h6>Technologies</h6>
            <h2>How we stack up for<br><strong>Digital Transformation</strong>.</h2>
            <p>We continuously evolve ourselves and add up new and emerging technologies to our arsenal. It ensures your sure-shot win in the digital transformation battleground. 
            </p>
        </div>
        <div class="techBox">
            <div class="row">
                <div class="col-md-3 col-6">
                    <div class="techParent">
                        <h3 class="techHead">Platform</h3>
                        <ul>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/dynamicsCrm.png" alt=""></span>
                                <span>Dynamic CRM                                </span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/powerBI.png" alt=""></span>
                                <span>Power BI
                                </span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/wordpress.png" alt=""></span>
                                <span>WP</span>
                            </li>
                          
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="techParent">
                        <h3 class="techHead">Web</h3>
                        <ul>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/c-sharp.png" alt=""></span>
                                <span>C#</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/netCore.png" alt=""></span>
                                <span>ASP.NET Core</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/laravel.png" alt=""></span>
                                <span>Laravel</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/angular.png" alt=""></span>
                                <span>Angular</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/react.png" alt=""></span>
                                <span>React</span>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="techParent">
                        <h3 class="techHead">Mobile</h3>
                        <ul>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/android.png" alt=""></span>
                                <span>Android</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/IOS.png" alt=""></span>
                                <span>iOS</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/xamarin.png" alt=""></span>
                                <span>Xamarin</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/reactNative.png" alt=""></span>
                                <span>React native
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="techParent">
                        <h3 class="techHead">DevOps</h3>
                        <ul>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/axure.png" alt=""></span>
                                <span>Azure</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/aws.png" alt=""></span>
                                <span>AWS</span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/continuosintegration.png" alt=""></span>
                                <span>Continuous Integration
                                </span>
                            </li>
                            <li class="techContent">
                                <span class="techImg"><img src="../../../../assets/continuosDelivery.png" alt=""></span>
                                <span>Continuous Delivery
                                </span>
                            </li>

                        </ul>
                    </div>
                </div>
               
                
            </div>
        </div>
    </div>
</div>