<div class="service-bg">
    <div class="service-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-4">
                    <h6>Our story</h6>
                    <h2>How it all <strong>started?</strong> </h2>
                </div>
                <div class="col-md-12 col-lg-7">
                    <div class="storyContent">
                        <p>A group of technology experts, with 15+ years of experience, from diverse sectors and industries came forward to achieve a single goal - <strong>securing the digital roots of our clients</strong>, thus
                            the name <strong>DeVirsa</strong>.
                        </p>
                        <p>With our roots in delivering agile-driven digital products and platforms, we’re at the cusp
                            of bringing the paradigm shift in the digital world with our innovation-steered engineering
                            capabilities, solving the toughest pain-points of our clients.
                        </p>
                        <p>In today’s customer-driven business landscape, Devirsa is known as a digital navigator of
                            various startups & enterprises, adding value for its customers by adding value for their
                            customers.
                        </p>
                        <p class="collapse" id="ourStoryCollapse">Powered by 15-years worth of experience, Devirsa has
                            been shaping the digital ambition of startups and enterprises by delivering custom scalable
                            digital products and platforms that outpace the speed of digital disruption. <br>
                            We are young and experienced - a combination that helps reimagine and innovate digital
                            experiences, which deliver value.
                        </p>
                        <button class="btn custom-btn mt-3" type="button" data-toggle="collapse"
                            data-target="#ourStoryCollapse" aria-expanded="false" aria-controls="ourStoryCollapse">Know
                            more about us</button>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>