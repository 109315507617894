<div class="usa-content">
    <div class="container">
        <h2>From India to the USA, we are thrilled to announce <br>that<strong> Devirsa </strong>is expanding its roots.</h2>
        <p class="mt-3">We have reached "Down Under (Australia)" with our partnership with Inphotech PYT Ltd <br/> to help business engineer their digital experiences.
        </p>
        <div class="m-5">
            <img src="../../../assets/usa-aus.svg">
        </div>
        <div class="usasec-footer">
            <p>Let's collaborate and Craft Some Memorable Digital Experiences</p>
            <a class="btn btn-primary"  routerLinkActive="active" routerLink="/contact">Contact Us</a>
        </div>
    </div>
</div>