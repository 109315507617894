import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-lists',
  templateUrl: './service-lists.component.html',
  styleUrls: ['./service-lists.component.scss']
})
export class ServiceListsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
