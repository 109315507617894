<div class="bannerContent">
    <div class="container">
        <div class="row">
            <div class="col-md-7 align-self-center">
                <div class="leftContent">
                    <h2>We Fuel Your Products & Platforms with</h2>
                    <h1>Digital Experience.</h1>
                    <p>Transforming businesses with a customized agile software development approach:
                       <strong> EMPATHY. ENGINEER. EXPERIENCE</strong>.</p>
                    <div class="btns-box">
                        <button data-toggle="modal" data-target="#ctaModal" class="btn btn-primary">
                            Book Free Consultation
                        </button>
                        <!-- <button class="btn no-bg">
                            <i class="fa fa-play" data-toggle="modal" data-target="#videoModal"></i> Watch Video
                        </button> -->
                    </div>
                    <!-- <div class="client-imgs">
                        <img src="../../../../assets/clients.png" alt="">
                    </div> -->
                </div>
            </div>
            <div class="col-md-5 align-self-center">
                <img src="../../../../assets/banner-img.png" alt="" class="bannerImg">
            </div>
        </div>
        <!-- <div class="counter-sect">
            <p>One week consulting at no obligation cost</p>
            <div class="client-count">
                <span>5</span>
                <span>6 </span> <i>,</i>
                <span>0</span>
                <span>5</span>
                <span>5</span>
            </div>
            <p class="clients-served">
                Total served clients
            </p>
        </div> -->
    </div>
</div>

<div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <a class="modal-close" href="" data-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i></a>
                <video width="100%" controls autoplay>
                    <source src="../../../../assets/sample-video.mp4" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="ctaModal" tabindex="-1" role="dialog" aria-labelledby="ctaModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content model-form">
            <div class="modal-header">
                <h5 class="modal-title" id="ctaModalLabel">Book Free Consultation</h5>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <form [formGroup]="formGroup" class="needs-validation" novalidate (ngSubmit)="submitForm()">

                    <div class="form-group">
                        <input class="form-control" placeholder="Full Name" name="Full Name" formControlName="name"
                            type="text" required>
                    </div>
                    <div class="form-group">
                        <input formControlName="email" type="email" class="form-control" name="Email Address"
                            placeholder="Email Address" aria-describedby="inputGroupPrepend" required>
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="Phone" name="Full Name" formControlName="phone"
                            type="text" required>
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="Business Type" name="Full Name" formControlName="businessType"
                            type="text" required>
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="Budget" name="Full Name" formControlName="budget"
                            type="text" required>
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="Time Frame" name="Full Name" formControlName="timeframe"
                            type="text" required>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control form-textArea" placeholder="Requirements" name="" id="" formControlName="requirement" required>
                        </textarea>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group mt-1">
                            <button type="button" class="btn custom-btn" data-dismiss="modal">Cancel</button>
                            <button class="btn btn-primary" type="submit" data-toggle="modal"
                            data-target="#cta-success" [disabled]="formGroup.invalid" data-dismiss="modal">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div id="cta-success" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
					<i class="fa fa-check"></i>
				</div>
				<!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
			</div>
			<div class="modal-body text-center">
				<h4>Great!</h4>	
				<p>Your message has been successfully sent</p>
				<button class="btn btn-primary" data-dismiss="modal" >Close</button>
			</div>
		</div>
	</div>
</div>     