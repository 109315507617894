<div class="achievements-content">
    <div class="container">
        <h6>Achievements</h6>
        <h2>Delivering results at the break-neck speed of trust, 
            <strong> for 15 years</strong>
        </h2>
        <div class="achieveList">
            <div class="row">
                <!-- <div class="col-md-3 col-sm-6">
                    <div class="details">
                        <p class="count">10+</p>
                        <p class="content">
                            Years experience
                        </p>
                    </div>
                </div> -->
                <div class="col-md-4 col-sm-6">
                    <div class="details">
                        <p class="count">3</p>
                        <p class="content">
                            Locations
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="details">
                        <p class="count">75+</p>
                        <p class="content">
                            Projects delivered
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="details">
                        <p class="count">100+</p>
                        <p class="content">
                            Clients Recommend Us
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>