import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import axios from 'axios'

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent implements OnInit {

  createFormGroup() {
    return new FormGroup({
      name: new FormControl(''),
      email: new FormControl('', Validators.email),
      companyname: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      service: new FormControl('', Validators.required),
      budget: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
    });
  }
  // formGroup:FormGroup;


  formGroup: FormGroup;

  constructor() {
    this.formGroup = this.createFormGroup();
  }

  ngOnInit(): void {
  }

  submitForm() {
    // this.formGroup.valid;
    console.log(this.formGroup.value);
    // this.formGroup = this.createFormGroup();
    axios.post("https://devirsa.com/testapi",this.formGroup.value).then(response=>{console.log(response)}).catch(response=>{console.error(response)})
  }

}
