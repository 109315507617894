import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import axios from 'axios'

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  createFormGroup() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      message: new FormControl('', Validators.required),
      policyConfirm: new FormControl('')
    });
  }
  // formGroup:FormGroup;
  

  formGroup: FormGroup;

constructor() {
  this.formGroup = this.createFormGroup();
}

  ngOnInit(): void {
    
  }

  submitForm() {
    // this.formGroup.valid;
    console.log(this.formGroup.value);
    // this.formGroup = this.createFormGroup();
    axios.post("https://devirsa.com/testapi",this.formGroup.value).then(response=>{console.log(response)}).catch(response=>{console.error(response)})
  }

}
