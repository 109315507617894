<div class="counter-sect">
    <p>One week consulting at no obligation cost</p>
    <div class="client-count">
        <span>1</span>
        <span>0 </span> 
        <!-- <i>,</i> -->
        <span>0</span>
        <span class="no-bg">+</span>
        <!-- <span>5</span> -->
    </div>
    <p class="clients-served">
        Total served clients
    </p>
</div>
<div class="about-content">
    <div class="container">
        <h6>About Company</h6>
        <h2>We are your next-generation <br><strong> Digital Transformation consultants </strong><br>driving business innovation.</h2>
        <p>We are digital navigators that help our clients enter the phygital world, with engineering-led, experience-rich digital products and platforms built with a unique approach: Empathy, Engineer, Experience.
        </p>
        <div class="about-box">
            <ul>
                <li>
                    <div class="img-box">
                        <img src="../../../../assets/response.svg" alt="">
                    </div>
                    <h3><strong>Empathy</strong></h3>
                    <p>Amalgamate data, emotions, and technology to digitalize empathy for our customer’s customers</p>
                </li>
                <li>
                    <div class="img-box">
                        <img src="../../../../assets/chat.svg" alt="">
                    </div>
                    <h3><strong>Engineer</strong></h3>
                    <p>Engineer experiences in digital products by infusing technology, design, and product-led thinking at every step</p>
                </li>
                <li>
                    <div class="img-box">
                        <img src="../../../../assets/satisfaction.svg" alt="">
                    </div>
                    <h3><strong>Experience</strong></h3>
                    <p>A customer-centric approach to engineer digital products that customers love, ensuring desired business outcomes
                    </p>
                </li>

            </ul>
        </div>
    </div>
</div>