<div class="container">
    <div class="headerContent">
      <a href="" routerLinkActive="active" routerLink="/home" class="logo">
        <img src="./../../../assets/main-logo.svg" alt="">
      </a>
      <div class="topNav">
        <nav class="navbar navbar-toggleable-md navbar-inverse bg-inverse">
          <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse bg-inverse" id="navbarCollapse">
            <div class="nav-head">
              <a href="javascript void(0)" class="close-icon" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <img src="../../../../assets/closeIcon.svg" alt="">
              </a>
              <a routerLinkActive="active" routerLink="/home"><img src="../../../../assets/main-logo-white.png" alt=""
                  class="logo-nav"></a>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item">
                <span><img src="../../../../assets/nav-home.svg" alt=""></span><a class="nav-link"
                  routerLinkActive="active" routerLink="/home" href="#">Home</a>
              </li>
              <li class="nav-item dropdown">
                <span><img src="../../../../assets/nav-services.svg" alt=""></span><a class="nav-link dropdown-toggle"
                  routerLinkActive="active" routerLink="/services" href="#" id="navbarDropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><a class="dropdown-item" routerLinkActive="active" routerLink="/services/cloud-service"
                      href="#">Cloud Service</a></li>
                  <li><a class="dropdown-item" routerLinkActive="active" routerLink="/services/custom-app-development"
                      href="#">Custom App Development</a></li>
                  <li><a class="dropdown-item" routerLinkActive="active" routerLink="/services/ui-ux"
                      href="#">User Interface & User Experience</a></li>
                  <li><a class="dropdown-item" routerLinkActive="active" routerLink="/services/workflow-automation"
                      href="#">Workflow Automation</a></li>
                </ul>
              </li>
              <!-- <li class="nav-item">
                <span><img src="../../../../assets/nav-insights.svg" alt=""></span><a class="nav-link"
                  routerLinkActive="active" routerLink="/blog" href="#">Insights</a>
              </li> -->
              <li class="nav-item">
                <span><img src="../../../../assets/nav-aboutUs.svg" alt=""></span> <a class="nav-link"
                  routerLinkActive="active" routerLink="/about" href="#">About</a>
              </li>
              <!-- <li class="nav-item">
                        <span><img src="../../../../assets/nav-work.svg" alt=""></span> <a class="nav-link"
                            routerLinkActive="active" routerLink="/work" href="#">Work</a>
                    </li> -->

            </ul>
          </div>
          <button class="btn custom-btn" routerLinkActive="active" routerLink="/contact">Contact
            Us</button>
        </nav>
      </div>

    </div>
  </div>