<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner" role="listbox">
        <!-- Slide One - Set the background image for this slide in the line below -->
        <div class="carousel-item active">
            <div class="carousel-caption d-md-block">
                <img src="../../../../assets/img-slide-content.png" alt="">
                <p class="lead">Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec
                    nibh rhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta
                    sapien, vel finibus erat felis sed neque. Etiam aliquet neque sagittis erat tincidunt aliquam.
                    Vestibulum at neque hendrerit.</p>
                <a href="javascript:void(0)" routerLinkActive="active" routerLink="/case-study-page"><span>Read the success story</span></a>
            </div>
        </div>
        <!-- Slide Two - Set the background image for this slide in the line below -->
        <div class="carousel-item">
            <div class="carousel-caption d-md-block">
                <img src="../../../../assets/img-slide-content.png" alt="">
                <p class="lead">Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec
                    nibh rhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta
                    sapien, vel finibus erat felis sed neque. Etiam aliquet neque sagittis erat tincidunt aliquam.
                    Vestibulum at neque hendrerit.</p>
                <a href="javascript:void(0)" routerLinkActive="active" routerLink="/case-study-page"><span>Read the success story</span></a>
            </div>
        </div>
        <!-- Slide Three - Set the background image for this slide in the line below -->
        <div class="carousel-item">
            <div class="carousel-caption d-md-block">
                <img src="../../../../assets/img-slide-content.png" alt="">
                <p class="lead">Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec
                    nibh rhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta
                    sapien, vel finibus erat felis sed neque. Etiam aliquet neque sagittis erat tincidunt aliquam.
                    Vestibulum at neque hendrerit.</p>
                <a href="javascript:void(0)" routerLinkActive="active" routerLink="/case-study-page"><span>Read the success story</span></a>
            </div>
        </div>
    </div>
    <div class="slide-arrow">
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <img src="../../../../assets/arrow-left.svg" alt="">
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <img src="../../../../assets/arrow-right.svg" alt="">
        </a>
    </div>
</div>