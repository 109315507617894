<div class="service-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 align-self-center">
                <div class="leftContent">
                    <h1>We deliver <strong>Custom Cloud Solutions</strong> to accelerate innovation, growth, security, and business value for our clients.</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="img-top">
    <div class="container">
        <div class="img-top-cont">
            <img src="../../../../../../assets/cloud-services/service-detail-top.png" alt="">
        </div>
    </div>
</div>
