import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-two-content',
  templateUrl: './service-two-content.component.html',
  styleUrls: ['./service-two-content.component.scss']
})
export class ServiceTwoContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
