<div class="services-provided">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/custom-app-development/application-development.png" alt="" class=""></div>
            </div>
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>Next-Generation Application Development and Management</h3>
                    <p>At Devirsa, we cover several application development and maintenance services such as DevOps, application management,  testing, and legacy modernization to help enterprises in an end-to-end manner with their product development and digital transformation requirements.
                    </p>
                    <p>Employing proprietary tools, methodologies, and business value-oriented approach, we:</p>
                    <ul>
                        <li>Enable you to assess your mobile application portfolio </li>
                        <li>Help manage applications that focus on driving pervasive cost reduction. With cost reductions, we cater to business outcomes during the regular engagements. dvdsfdsd
                        </li>
                        
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>

                </div>
            </div>
        </div>
        <div class="row flex-column-reverse flex-md-row service-row-Mt">
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3 class="service-text-Mt">Mobile Business App Development
                    </h3>
                    <p>Building customized mobile applications start with a deep understanding of the market’s needs, requirements, and capabilities. Our team of highly skilled developers and strategists can develop a success-oriented mobile strategy for your company. We deliver outstanding technology solutions that drive a more significant ROI.</p>
                    <p>Devirsa ensures that our application development services are well-aligned to your goals and expectations. </p>
                    <ul>
                        <li>Right from business analysis to creating design and development, we employ top-notch technologies to turn your ideas real.</li>
                        <li>With our iterative approach, you get digital products at the utmost efficiency, delivered on time and budget.</li>
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/custom-app-development/mobile-app.png" alt="" class="">
                </div>
            </div>
        </div>
        <div class="row service-row-Mt">
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/custom-app-development/digital-strategy.png" alt="" class=""></div>
            </div>
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>Digital Product Strategizing
                    </h3>
                    <p>Our product strategizing process begins with assessing the factors that influence your success. To build products that outshine, we make your users the point upon which product strategy pivots. Followed by the implementation of leading-edge technology, we enhance growth opportunities with robust and scalable solutions.
                    </p>
                    <p>What makes our digital product strategy, precisely, what your business needs?                    </p>
                    <ul>
                        <li>Our insightful and articulated strategic vision keeps the development process aligned, right from start to finish. </li>
                        <li>We blend concise and verified methodologies with design thinking and advanced UX/UI techniques to deliver a future-proof strategic roadmap.</li>
                        <li>Defining business and audience goals, we establish a clear vision for identifying success metrics. 
                        </li>
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>
                </div>
            </div>
        </div>
        <div class="row flex-column-reverse flex-md-row">
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3 class="service-text-Mt">Application Modernization</h3>
                    <p>We help businesses pivot from pondering digital to being digital by reimagining and reprioritizing business models and products, driven by high-end CX-related technologies and a digital mindset. Turn your applications more agile and efficient with our application modernization practices.
                    </p>
                    <p>Want to move your applications to a newer and more efficient technology platform? We’ve got you covered.
                    </p>
                    <ul>
                        <li>We add value for your enterprise, making complete use of your existing application code investments. </li>
                        <li>Embrace modern development practices, along with enhanced security to transfer information into new environments. 
                        </li>
                    </ul>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/custom-app-development/application-modernization.png" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</div>