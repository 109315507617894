import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import axios from 'axios'

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {

  createFormGroup() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      phone: new FormControl('', Validators.required),
      businessType: new FormControl('', Validators.required),
      requirement: new FormControl('', Validators.required),
      budget: new FormControl('', Validators.required),
      timeframe: new FormControl('', Validators.required),

    });
  }
  // formGroup:FormGroup;


  formGroup: FormGroup;

  constructor() {
    this.formGroup = this.createFormGroup();
  }

  ngOnInit(): void {

  }

  submitForm() {
    // this.formGroup.valid;
    console.log(this.formGroup.value);
    // this.formGroup = this.createFormGroup();
    axios.post("https://devirsa.com/testapi",this.formGroup.value).then(response=>{console.log(response)}).catch(response=>{console.error(response)})
  }

}
