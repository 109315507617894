<div class="about-goal-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <h6>Our Goal</h6>
                    <h2>We consult, design & build <br>your <strong> business for tomorrow’s success today</strong>.</h2>
                    <p>Things have evolved quickly since we launched nearly two decades ago, but here’s what’s stayed the same: Ambitious businesses succeed by delivering transformative digital experiences to customers. </p> 
                    <p> We’re the partner you can count on for results-driven digital products & platforms.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightContent">
                    <img src="../../../../assets/about_goal_img.png" alt="">
                </div>
            </div>

        </div>
    </div>
</div>
