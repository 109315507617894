<div class="service-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <p class="heading-text">We Build Your Competitive <br> Advantage in the</p>
                    <h1>Digital Age</h1>
                    <p>DeVirsa steers business models by designing, building, and delivering digital products, platforms, and experiences. Our expertise lies in client and customer-centric, experience-led engineering.
                    </p>
                    <div class="btns-box">
                        <button routerLinkActive="active" routerLink="/contact" class="btn btn-primary">
                            Book Free Consultation
                        </button>
                        <!-- <button class="btn no-bg">
                            <i class="fa fa-play" data-toggle="modal" data-target="#videoModal"></i> Watch Video
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightContent">
                    <img class="" src="../../../../assets/service-top.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
