import { Component, OnInit,NgModule, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
// import { AppComponent } from '../../../app.component';
import {FooterComponent} from '../../../common/footer/footer.component'

@Component({
  selector: 'app-join-team',
  templateUrl: './join-team.component.html',
  styleUrls: ['./join-team.component.scss'],
})

// @NgModule({
//   imports:      [ CommonModule],
//   declarations: [ FooterComponent], 
//   exports: [ FooterComponent], 
// })

export class JoinTeamComponent implements OnInit {

  @ViewChild("email") nameField: ElementRef;
  subscribeUs(): void {
    this.nameField.nativeElement.focus();
  }
  constructor() { }

  ngOnInit(): void {
  }

}
