<div class="MainContent">
    <header>
      <app-header></app-header>
    </header>
    <section class="content">
      <div class="aboutMission">
        <app-about-banner></app-about-banner>
      </div>
      <div class="aboutStory">
        <app-about-service></app-about-service>
      </div>
      <app-about-slider></app-about-slider>
      <app-choose-us></app-choose-us>
      <!-- <app-about-goal></app-about-goal> -->
      <app-achievements></app-achievements>
      <!-- <app-usasec></app-usasec> -->
      <!-- <div class="testimonials">
        <app-slider></app-slider>
      </div> -->
      <app-join-team></app-join-team>
      <app-contact-section></app-contact-section>
      
    </section>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>