import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technology-stack',
  templateUrl: './technology-stack.component.html',
  styleUrls: ['./technology-stack.component.scss']
})
export class TechnologyStackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
