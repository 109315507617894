<div class="MainContent">
    <header>
      <app-header></app-header>
    </header>
    <section class="content">
      <app-work-banner></app-work-banner>

      <app-work-filter></app-work-filter>
     
    </section>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>