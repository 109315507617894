<div class="MainContent">
    <header>
        <app-header></app-header>
    </header>
    <section class="content">
        <div class="container">
            <div class="blog-detail-box">
                <h1 class="blog-head">
                    Cras gravida bibendum dolor eu varius. Morbi fermentum velit nisl, eget vehicula lorem sodales eget.
                    Donec quis volutpat orci.
                </h1>
                <div class="blog-date">
                    <span class="author">By Adam Adams</span>
                    <span class="blog-detail">November 17, 2020</span>
                </div>
                <div class="blog-img">
                    <img src="../../../../assets/blog/blog-detail-img.png" alt="">
                </div>
                <div class="blog-content">
                    <p>Pellentesque tincidunt tristique neque, eget venenatis enim gravida quis. Fusce at egestas
                        libero. Cras convallis egestas ullamcorper. Suspendisse sed ultricies nisl, pharetra rutrum
                        mauris. Vestibulum at massa dui. Morbi et purus velit. Etiam tristique, justo eu condimentum
                        efficitur, purus velit facilisis sem, id fringilla tortor quam quis dolor. Praesent ultricies
                        dignissim ex, at volutpat sapien ullamcorper rhoncus. Curabitur quam velit, ullamcorper ut
                        congue eget, convallis et velit. Donec placerat, magna eu venenatis tempus, dui sapien aliquam
                        libero, sit amet maximus erat massa quis nisi. Integer pharetra auctor arcu, non tincidunt dui
                        fermentum ut. Nullam interdum sapien id mauris dapibus, a pharetra purus rhoncus. Nullam viverra
                        iaculis tristique. Donec quis mauris ipsum. Nunc vehicula magna at erat tristique rutrum.
                    </p>
                    <p>
                        Pellentesque tincidunt tristique neque, eget venenatis enim gravida quis. Fusce at egestas
                        libero. Cras convallis egestas ullamcorper. Suspendisse sed ultricies nisl, pharetra rutrum
                        mauris. Vestibulum at massa dui. Morbi et purus velit. Etiam tristique, justo eu condimentum
                        efficitur, purus velit facilisis sem, id fringilla tortor quam quis dolor. Praesent ultricies
                        dignissim ex, at volutpat sapien ullamcorper rhoncus. Curabitur quam velit, ullamcorper ut
                        congue eget, convallis et velit. Donec placerat, magna eu venenatis tempus, dui sapien aliquam
                        libero, sit amet maximus erat massa quis nisi. Integer pharetra auctor arcu, non tincidunt dui
                        fermentum ut. Nullam interdum sapien id mauris dapibus, a pharetra purus rhoncus. Nullam viverra
                        iaculis tristique. Donec quis mauris ipsum. Nunc vehicula magna at erat tristique rutrum.
                    </p>
                </div>
                <div class="comment-sect">
                    <img src="../../../../assets/comment-img.png" alt="">
                    <p>Pellentesque tincidunt tristique neque, eget venenatis enim gravida quis. Fusce at egestas
                        libero. Cras convallis egestas ullamcorper. Suspendisse sed ultricies nisl.</p>
                </div>
                <div class="client-comment-sect">
                    <div class="bg-box">
                        <h3>Comments</h3>
                       <div class="commentBoxParent">
                        <div class="commentBox">
                            <!-- <img src="../../../../assets/comment-user.png" alt="" class="leftImg"> -->
                            <div class="rytContent">
                                <p class="headText">
                                    Dorothy Hill
                                </p>
                                <p>
                                    Aliquam in bibendum mauris. Sed vitae erat vel velit blandit pharetra vitae nec
                                    ante. Cras at est augue. Cras ut interdum elit. Ut malesuada a urna sit amet
                                    blandit.
                                </p>
                                <div class="add-info">
                                    <!-- <span class="reply">
                                        <a href="javascript:void(0)"> Reply <i class="fa fa-share"></i></a>
                                    </span> -->
                                    <span class="date">
                                        15 June 2020
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="commentBox">
                            <!-- <img src="../../../../assets/comment-user.png" alt="" class="leftImg"> -->
                            <div class="rytContent">
                                <p class="headText">
                                    Aliquam Bill
                                </p>
                                <p>
                                    bibendum mauris. Sed vitae erat vel velit blandit pharetra vitae nec
                                    ante. Cras at est augue. Cras ut interdum elit. Ut malesuada a urna sit amet
                                    blandit.
                                </p>
                                <div class="add-info">
                                    <!-- <span class="reply">
                                        <a href="javascript:void(0)"> Reply <i class="fa fa-share"></i></a>
                                    </span> -->
                                    <span class="date">
                                        16 June 2020
                                    </span>
                                </div>
                            </div>
                        </div>
                       </div>
                        <div class="replyBox">
                            <p>Leave your Comment</p>
                            <form class="reply-form needs-validation" [formGroup]="formGroup" novalidate
                            (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea placeholder="Your Reply" class="form-control form-textArea"
                                                required formControlName="reply"></textarea>
                                            <!-- <div class="invalid-feedback">This field is required</div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input class="form-control" required placeholder="Your Name"
                                                formControlName="name" type="text" required>
                                            <!-- <div class="invalid-feedback">This field is required</div> -->
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" name="Email Address"
                                                placeholder="Your Email" aria-describedby="inputGroupPrepend"
                                                formControlName="email" required>
                                            <!-- <div class="invalid-feedback">Please enter valid E-mail ID</div> -->
                                        </div>
                                    </div>

                                </div>
                                <div class="postBtn">
                                    <button type="submit" class="btn btn-primary" data-toggle="modal"
                                    data-target="#blog-comment" [disabled]="formGroup.invalid">Post Comment</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div id="blog-comment" class="modal fade">
            <div class="modal-dialog modal-confirm">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="fa fa-check"></i>
                        </div>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
                    </div>
                    <div class="modal-body text-center">
                        <h4>Great!</h4>
                        <p>Your message has been successfully sent</p>
                        <button class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <app-footer></app-footer>
    </footer>
</div>