<div class="services-provided">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/cloud-services/cloud-migration.png" alt="" class=""></div>
            </div>
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3>Cloud Migration</h3>
                    <p>Take your business to the next level with enhanced workload scalability and least-risk approach to mitigate cloud migration complexities. Our solutions help you attain flexibility by simplifying cloud migration. Efficient monitoring is possible with effective cloud migration that helps centralize the monitoring process, thereby saving energy and time to keep accessing & checking each application & element!
                    </p>
                    
                    <ul>
                        <li>We at Devirsa ensure data safety & security by deploying award-winning cloud migration strategies.</li>
                        <li>Our strategies are prepared in line with the best industrial practices concerning automation, scalability, security, automation, disaster recovery, and more.
                        </li>
                    </ul>
                    <p>Let our expert team handle cloud migration to keep you safe from any vulnerability. 
                    </p>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>

                </div>
            </div>
        </div>
        <div class="row flex-column-reverse flex-md-row service-row-Mt">
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3 class="service-text-Mt">Cloud-Enabled Enterprise Apps
                    </h3>
                    <p>Boost process efficiency and deliver an exquisite customer experience with optimized cloud-enabled enterprise business applications. Devirsa ensures for improved business performance by bestowing you with market differentiation. Our efficient, agile, and cost-saving solutions help you build on strong enterprise pillars.
                    </p>
                    
                    <ul>
                        <li>Give a boost to streamlining processes by getting proficient application configuration solutions to meet all your enterprise’s requirements.</li>
                        <li>We help you harness unique & latest ways via better-enabled technology and monitor application performance with real-time performance visibility.
                        </li>
                    </ul>
                    <p>Our team helps in improving agility and provides seamless customer experience.
                    </p>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/cloud-services/cloud-apps.png" alt="" class="">
                </div>
            </div>
        </div>
        <div class="row service-row-Mt">
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/cloud-services/cloud-consulting.png" alt="" class=""></div>
            </div>
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3> Cloud Consulting
                    </h3>
                    <p>Make an easy and reliable shift to cloud computing by resorting to Devirsa’s cloud consultancy. We create results-driven, digital possibilities & innovations with the help of cost-saving and high-performance cloud services. We help you retain your focus on business core activities by realizing strategic IT objectives quicker than before.
                    </p>
                    <ul>
                        <li>Devirsa turns your expectations into reality and lets you attain the desired expected value from cloud initiatives. 
                        </li>
                        <li>We work on bridging the gap between aspiration and reality by giving ultimate cloud solutions, laying on the foundation of DevSecOps, ready to scale and grow.
                        </li>
                    </ul>
                    <p>Plan cloud strategy and execute it with brilliance by counting on our solutions!
                    </p>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>
                </div>
            </div>
        </div>
        <div class="row flex-column-reverse flex-md-row">
            <div class="col-md-8 align-self-center">
                <div class="textContent">
                    <h3 class="service-text-Mt">CloudOps</h3>
                    <p>At Devirsa, we work on meeting the technical and business needs of our patrons. Our CloudOps services help you build and operate the right cloud solution. CloudOps helps you keep your focus on creating efficiency and value for your business with more innovations and the least overheads.
                    </p>
                    <ul>
                        <li>CloudOps improves hardware and software performance by balancing up the load and improving networking solutions. 
                        </li>
                        <li>We help you unlock new technologies with new business models and let you move forward with sustainability.
                        </li>
                    </ul>
                    <p>Get faster transformations, approved business solutions, and empower your journey by resorting to Devirsa.
                    </p>
                    <button routerLinkActive="active" routerLink="/contact" class="btn custom-btn">Request a free Consultation</button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow-img"><img src="../../../../../../assets/cloud-services/cloud-ops.png" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</div>