<div class="form-content">
    <div class="container">
        <h3>Send us a Message</h3>
        <!-- <form [formGroup]="this.formGroup" class="needs-validation" novalidate (validSubmit)="this.handleValidSubmit()"> -->
        <form [formGroup]="formGroup" class="needs-validation" novalidate (ngSubmit)="submitForm()">

            <div class="form-group">
                <label for="Full Name">Full Name</label>
                <input class="form-control" placeholder="Full Name" name="Full Name" formControlName="name" type="text"
                    required>
            </div>
            <div class="form-group">
                <label for="Email Address">Email Address</label>
                <input formControlName="email" type="email" class="form-control" name="Email Address"
                    placeholder="Email Address" aria-describedby="inputGroupPrepend" required>

                <!-- <div class="invalid-feedback">Enter valid Email Id</div> -->
            </div>
            <div class="form-group">
                <label for="help">Write your message here</label>
                <textarea class="form-control form-textArea" placeholder="How we can help" name="Your Message"
                    formControlName="message" required></textarea>
            </div>

            <div class="form-group custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="agreeBox" formControlName="policyConfirm" required>
                <label class="custom-control-label" for="agreeBox">I agree to <a href="#" routerLinkActive="active" routerLink="/privacy-policy" target="_blank">Privacy Policy</a> & <a href="#" routerLinkActive="active" routerLink="/terms-condition" target="_blank">Terms-Conditions</a>.
                </label>


            </div>
            <div class="form-group mt-1">
                <button class="btn custom-btn-green" type="submit" data-toggle="modal"
                    data-target="#contact-success" [disabled]="formGroup.invalid">I’m
                    Done</button>
            </div>
            <!-- <button class="btn custom-btn-green" type="submit">I’m
                Done</button> -->
        </form>
    </div>
</div>

<!-- Modal -->
<div id="contact-success" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
					<i class="fa fa-check"></i>
				</div>
				<!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
			</div>
			<div class="modal-body text-center">
				<h4>Great!</h4>	
				<p>Your message has been successfully sent</p>
				<button class="btn btn-primary" data-dismiss="modal" >Close</button>
			</div>
		</div>
	</div>
</div>     