<!-- CONTACTS-1
				============================================= -->
                <section id="contacts-1" class="contacts-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 offset-lg-1 section-title">
        
                                <!-- Title 	-->
                                <h2>Let’s Build Experience, Together
                                </h2>
        
                                <!-- Text -->
                                <p class="p-lg">No Strings Attached, Free Consultation.
                                </p>
        
                            </div>
                            <div class="col-lg-10 offset-lg-1 section-content">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="contact-list">
                                            <img src="../../../../assets/Contact/our-timeline.svg" alt="">
                                            <h4>Our Timeline:</h4> <span> 2 weeks
                                                satisfied product delivery</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="contact-list">
                                            <img src="../../../../assets/Contact/our-unique-process.svg" alt="">
                                            <h4>Our Unique Process:</h4><span> Listen,
                                                Speak, Weave, Deliver</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="contact-list">
                                            <img src="../../../../assets/Contact/our-approach.svg" alt="">
                                            <h4>Our Approach:</h4><span>
                                                <span class="infinite-symbol">∞</span> Infinite revisions, until our customer is
                                                happy</span>
                                        </div>
                                    </div>
        
                                </div>
        
                            </div>
                            <!-- CONTACTS INFO -->
                            <div class="col-lg-6 mt-20">
                                <div class="contacts-info pc-25">
                                    <img src="../../../../assets/Contact/contact-img.svg" alt="">
                                </div>
                            </div> <!-- END CONTACTS INFO -->
        
        
                            <!-- CONTACT FORM -->
                            <div class="col-lg-6">
                                <div class="form-holder mb-40 pc-25">
                                    <form id="contactForm" name="contactform" [formGroup]="formGroup" novalidate (ngSubmit)="submitForm()" class="row contact-form needs-validation">
                                        <!-- Contact Form Input -->
                                        <div id="input-name" class="col-md-6">
                                            <input type="text" name="name" class="form-control name" placeholder="Your Name" formControlName="name">
                                        </div>
        
                                        <div id="input-email" class="col-md-6">
                                            <input type="email" name="email" class="form-control email"
                                                placeholder="Email Address" formControlName="email" required>
                                        </div>
                                        <div id="input-company" class="col-md-6">
                                            <input type="text" name="company-name" class="form-control name" placeholder="Your Company" formControlName="companyname" required>
                                        </div>
        
                                        <div id="input-phone" class="col-md-6">
                                            <input type="text" name="phone" class="form-control name" placeholder="Phone Number"  formControlName="phone" required>
                                        </div>
                                        <div id="select-category" class="col-md-6">
                                            <select id="" class="form-control" formControlName="service" required>
                                                <option value="">I'm Interested in</option>
                                                <option value="Consulting Services">Consulting Services</option>
                                                <option value="Web Development">Web Development</option>
                                                <option value="Mobile App Development">Mobile App Development</option>
                                                <option value="AI Machine Learning">AI Machine Learning</option>
                                                <option value="Cloud Migration/Consulting">Cloud Migration/Consulting</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div id="select-budget" class="col-md-6">
                                            <select id="" class="form-control" formControlName="budget" required>
                                                <option value="">Select Budget</option>
                                                <option value="$500 to $1,000 USD">$500 to $1,000 USD</option>
                                                <option value="$1,000 to $5,000 USD">$1,000 to $5,000 USD</option>
                                                <option value="$5,000 to $10,000 USD">$5,000 to $10,000 USD</option>
                                                <option value="$1000+ USD">$1000+ USD</option>
                                            </select>
                                        </div>
        
                                        <!-- <div id="input-subject" class="col-md-12">
                                                <input type="text" name="subject" class="form-control subject"
                                                    placeholder="What's this about?">
                                            </div> -->
        
                                        <div id="input-message" class="col-md-12 input-message">
                                            <textarea class="form-control message" name="message" rows="6"
                                                placeholder="Project Requirements"  formControlName="message" required></textarea>
                                        </div>
        
                                        <!-- Contact Form Button -->
                                        <div class="col-lg-12 mt-10 form-btn">
                                            <button type="submit" class="btn btn-md btn-primary tra-black-hover submit g-recaptcha" data-toggle="modal"
                                            data-target="#contact-success" [disabled]="formGroup.invalid"
                                               >Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div> <!-- END CONTACT FORM -->
        
        
        
        
                        </div> <!-- End row -->
                    </div> <!-- End container -->
                </section> <!-- END CONTACTS-1 -->

<!-- Modal -->
<div id="contact-success" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
					<i class="fa fa-check"></i>
				</div>
				<!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
			</div>
			<div class="modal-body text-center">
				<h4>Great!</h4>	
				<p>Your message has been successfully sent</p>
				<button class="btn btn-primary" data-dismiss="modal" >Close</button>
			</div>
		</div>
	</div>
</div>     