<div class="MainContent">
    <header>
      <app-header></app-header>
    </header>
    <section class="content">
      <app-banner></app-banner>
      <app-services></app-services>
      <app-about></app-about>
      <app-achievements></app-achievements>
      <!-- <app-usasec></app-usasec> -->
      <div class="testimonials">
        <app-slider></app-slider>
      </div>
      <!-- <app-blogs></app-blogs> -->
      <app-contact-section></app-contact-section>
    </section>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>