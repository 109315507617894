<div class="blog-banner-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="leftContent">
                    <h1>Read the latest<strong> digital trends, analysis, <br> and perspectives </strong> from Devirsa’s Insights.</h1>
                    <p>You can receive these latest Digital Insights straight to your inbox too. Just sign-up for free.
                    </p>
                    <div class="btns-box"><button routerlinkactive="active" class="btn btn-primary"> Subscribe Us </button></div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightContent">
                    <img class="" src="../../../../assets/blog/blog-banner.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
