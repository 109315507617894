<div class="MainContent">
  <header>
    <app-services-nav></app-services-nav>
  </header>
    <section class="content">
      <app-service-detail-banner></app-service-detail-banner>
      <app-s-detail-content></app-s-detail-content>
      <app-technology-stack></app-technology-stack>
      <app-get-started></app-get-started>
    </section>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>