import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-four-banner',
  templateUrl: './service-four-banner.component.html',
  styleUrls: ['./service-four-banner.component.scss']
})
export class ServiceFourBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
