<div class="MainContent">
  <header>
    <app-services-nav></app-services-nav>
  </header>
  <section class="content">
    <app-service-banner></app-service-banner>
    <div class="service-content">
      <div class="services-provided">
        <!-- Start Nav Section -->
        <nav id="navbar" class="navbar">

          <ul class="nav-menu">
            <li>
              <a placement="left" [closeDelay]="200" ngbTooltip="Cloud Service" data-scroll="cloudMigration"
                href="#cloudMigration" class="dot">

              </a>
            </li>
            <li>
              <a placement="left" [closeDelay]="200" ngbTooltip="Custom App Development" data-scroll="customAppDev"
                href="#customAppDev" class="dot">

              </a>
            </li>
            <li>
              <a placement="left" [closeDelay]="200" ngbTooltip="UI/UX Experience" data-scroll="uiUXExp" href="#uiUXExp"
                class="dot">

              </a>
            </li>
            <li>
              <a placement="left" [closeDelay]="200" ngbTooltip="Explainer Video" data-scroll="explVideo"
                href="#explVideo" class="dot">

              </a>
            </li>

          </ul>
        </nav>
        <!-- End Nav Section -->
        <div class="container">
          <section id="cloudMigration" class="scroll-Content">
            <div class="row">
              <div class="col-md-4">
                <div class="shadow-img"><img src="../../../assets/cloud-migration.png" alt="" class=""></div>
              </div>
              <div class="col-md-8 align-self-center">
                <div class="textContent">
                  <h3>Cloud Service</h3>
                  <p>We enable businesses to speed up their digital transformation journey.  Our cloud migration process with a low-risk approach ensures a secure digital business standing on the DevSecOps foundation.
                  </p>
                  <ul>
                    <li>Cloud Readiness</li>
                    <li>Cloud-Enabled Enterprise Apps</li>
                    <li>Cloud Consulting</li>
                    <li>CloudOps</li>
                  </ul>
                  <button routerLinkActive="active" routerLink="/services/cloud-service" class="btn custom-btn">Learn
                    more</button>

                </div>
              </div>
            </div>
          </section>

          <section id="customAppDev" class="scroll-Content">
            <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-8 align-self-center">
                <div class="textContent">
                  <h3 class="service-text-Mt">Custom App Development</h3>
                  <p>We build software and products that underpin customer experience and steer business models in today’s digital era. Our innovative and client-centric digital engineering strategies reduce the time spent across various stages of the Software Development Lifecycle (SDLC). 
                  </p>
                  <ul>
                    <li>Next-Generation Application Development and Management</li>
                    <li>Mobile App Development</li>
                    <li>Digital Product Strategizing</li>
                    <li>Application Modernization</li>
                  </ul>
                  <button routerLinkActive="active" routerLink="/services/custom-app-development" class="btn custom-btn">Learn
                    more</button>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shadow-img"><img src="../../../assets/custom-app-development.png" alt="" class="">
                </div>
              </div>
            </div>
          </section>

          <section id="uiUXExp" class="scroll-Content">
            <div class="row service-row-Mt">
              <div class="col-md-4">
                <div class="shadow-img"><img src="../../../assets/ui-ux-experience.png" alt="" class="">
                </div>
              </div>
              <div class="col-md-8 align-self-center">
                <div class="textContent">
                  <h3>User Interface & User Experience</h3>
                  <p>Our UX transformation services bridge customers’ needs with business objectives, thereby improving the customer experiences across all the touchpoints. Our UX services build seamless digital experiences for our customer’s customers.	</p>
                  <ul>
                    <li>Product Prototyping
                    </li>
                    <li>Usability Testing & Validation</li>
                    <li>Service Design
                    </li>
                    <li>UX Consulting</li>
                  </ul>
                  <button routerLinkActive="active" routerLink="/services/ui-ux" class="btn custom-btn">Learn
                    more</button>
                </div>
              </div>
            </div>
          </section>

          <section id="explVideo" class="scroll-Content">
            <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-8 align-self-center">
                <div class="textContent">
                  <h3 class="service-text-Mt">Workflow Automation</h3>
                  <p>Reinvent and reimagine your business process by creating automated workflows. Automation building services pair up with emerging technologies, empower business professionals.  </p>
                  <ul>
                    <li>Document Intelligence</li>
                    <li>Workflow Dashboards</li>
                    <li>Digital Process Automation</li>
                    <li>AI Cognitive Services</li>
                  </ul>
                  <button routerLinkActive="active" routerLink="/services/workflow-automation" class="btn custom-btn">Learn
                    more</button>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shadow-img"><img src="../../../assets/explainer-video-production.png" alt="" class="">
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <app-our-approach></app-our-approach>
    <app-technology-stack></app-technology-stack>
    <!-- <app-case-study></app-case-study> -->
    <app-get-started></app-get-started>
  </section>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>