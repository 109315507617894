<div class="service-bg">
    <div class="service-content">
        <div class="container">
            <h6>Services</h6>
            <!-- <h2>Guiding you through your <br> <strong>Digital Transformation</strong></h2> -->
            <h2>Transform Digitally, <br><strong> with Digital Mindsets. </strong></h2>
            <p>Digital Disruption is real, and we turn this disruption to our client’s advantage by
                bringing together <br>technology and digital trends to build digital products and platforms,
                together.</p>
            <app-company-service-list></app-company-service-list>
        </div>
    </div>
</div>