import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import axios from "axios";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	//Get Current Year
	currentYear: number = new Date().getFullYear();
	public show: boolean = false;

	createFormGroup() {
		return new FormGroup({
			email: new FormControl("", Validators.email),
		});
	}
	// formGroup:FormGroup;

	formGroup: FormGroup;

	constructor() {
		this.formGroup = this.createFormGroup();
	}

	ngOnInit(): void {}

	submitForm() {
		// this.formGroup.valid;
		console.log(this.formGroup.value);
		// this.formGroup = this.createFormGroup();
		axios
			.post("https://devirsa.com/testapi", this.formGroup.value)
			.then((response) => {
        console.log(response);
        this.show = true;
			})
			.catch((response) => {
				console.error(response);
			});
	}
}
